









import { defineComponent, PropType } from '~/utils/nuxt3-migration'
import { ViewType } from '~/models/shared/types'

export default defineComponent({
  props: {
    viewType: {
      type: String as PropType<ViewType>,
      default: ViewType.LIST
    },
    galleryLgPerRow: {
      type: Number,
      default: 4
    }
  }
})
