

























import BaseSearchHandler from './BaseSearchHandler.vue'
import { mapBackendSearchableToOption } from '~/services/search/formatters'
import { Option } from '~/models/shared/types'
import CSquareCheckbox from '~/components/shared/configurable/form/checkbox/CSquareCheckbox.vue'
import { defineComponent } from '~/utils/nuxt3-migration'

export default defineComponent({
  components: {
    CSquareCheckbox
  },
  extends: BaseSearchHandler,
  data() {
    return {
      selectedValues: []
    }
  },
  computed: {
    sizesOptions(): Option[] {
      return this.searchables.map(mapBackendSearchableToOption)
    }
  },
  methods: {
    onBackendValues({ selected }) {
      this.selectedValues = selected
    },
    handleValueChange(value) {
      this.selectedValues = value
      this.emitParams([
        {
          name: this.defaultUrlArgName,
          value
        }
      ])
    }
  }
})
