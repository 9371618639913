

































import CAvatar from '~/components/shared/configurable/image/avatar/CAvatar.vue'
import { computed, defineComponent, toRefs } from '~/utils/nuxt3-migration'

export default defineComponent({
  components: { CAvatar },
  props: {
    user: {
      type: Object,
      required: true
    },
    inLine: {
      type: Boolean,
      required: false,
      default: false
    },
    multiline: {
      type: Boolean,
      required: false,
      default: false
    },
    rounded: {
      type: Boolean,
      required: false,
      default: false
    },
    classifiedOwned: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup(props) {
    const { classifiedOwned, user, rounded, inLine } = toRefs(props)

    const showAvatar = computed(
      () => user.value.avatar || classifiedOwned.value
    )

    const avatarEditable = computed(
      () => !user.value.avatar && classifiedOwned.value
    )

    const avatarWidth = computed(() => {
      if (inLine.value) {
        return rounded.value ? 27 : 75
      }

      return avatarEditable.value ? 48 : 200
    })

    const avatarHeight = computed(() => {
      return inLine.value ? 27 : 48
    })

    return { showAvatar, avatarWidth, avatarEditable, avatarHeight }
  }
})
