



























import BaseSearchHandler from '~/components/car/classifieds/search/facets/handlers/BaseSearchHandler.vue'
import { debounce } from '~/utils/function'
import { DEBOUNCE_DEFAULT } from '~/constants/debounce'
import { defineComponent } from '~/utils/nuxt3-migration'

export default defineComponent({
  extends: BaseSearchHandler,
  data() {
    return {
      selectedLow: null,
      selectedHigh: null
    }
  },
  methods: {
    onBackendValues({ selected }) {
      const { low, high } = selected
      this.selectedLow = low
      this.selectedHigh = high
    },
    handleLowChange: debounce(function(lowValue: string) {
      this.emitParams([
        {
          name: `${this.defaultUrlArgName}-from`,
          value: lowValue
        }
      ])
    }, DEBOUNCE_DEFAULT),
    handleHighChange: debounce(function(highValue: string) {
      this.emitParams([
        {
          name: `${this.defaultUrlArgName}-to`,
          value: highValue
        }
      ])
    }, DEBOUNCE_DEFAULT)
  }
})
