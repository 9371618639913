


















































































import CSlider from '~/components/shared/configurable/slider/CSlider.vue'
import { formatNumber } from '~/utils/number'
import { defineComponent, PropType } from '~/utils/nuxt3-migration'

export interface SliderBarChartOption {
  value: any
  size: number
}

export interface EnhancedSliderBarChartOption extends SliderBarChartOption {
  height: string
}

export default defineComponent({
  components: { CSlider },
  props: {
    options: {
      type: Array as PropType<SliderBarChartOption[]>,
      default() {
        return []
      }
    },
    sizeSuffix: {
      type: String,
      default: ''
    },
    value: {
      type: Object as PropType<{ from: number; to: number }>,
      default() {
        return {
          from: null,
          to: null
        }
      }
    }
  },
  data() {
    const { from, to } = this.preprocessValue(this.value)
    return {
      sliderSelectedValue: [from, to],
      selectedRange: {
        from,
        to
      }
    }
  },
  computed: {
    sliderOptions() {
      return {
        processStyle: {
          backgroundColor: '#1976d2',
          height: '3px'
        },
        tooltipStyle: {
          backgroundColor: '#1976d2'
        }
      }
    },
    optionsSizes(): number[] {
      return this.options.map(option => option.size)
    },
    optionsValues(): number[] {
      return this.options.map(option => option.value)
    },
    maxOptionSize(): number {
      return Math.max(...this.optionsSizes)
    },
    maxOptionValue(): number {
      return Math.max(...this.optionsValues)
    },
    minOptionSize(): number {
      return Math.min(...this.optionsSizes)
    },
    minOptionValue(): number {
      return Math.min(...this.optionsValues)
    },
    formattedMaxOptionSize() {
      return formatNumber(this.maxOptionSize)
    },
    formattedMinOptionSize() {
      return formatNumber(this.minOptionSize)
    },
    enhancedOptions(): EnhancedSliderBarChartOption[] {
      return this.options.map(option => {
        const { size } = option
        const { minOptionSize, maxOptionSize } = this
        const height: number = this.scaleNumberBetween(
          size || 0,
          1,
          45,
          minOptionSize,
          maxOptionSize
        )
        return {
          ...option,
          height: `${height}px`
        }
      })
    }
  },
  watch: {
    value(newValue) {
      const { from, to } = this.preprocessValue(newValue)
      this.selectedRange.from = from
      this.selectedRange.to = to
      this.sliderSelectedValue = [from, to]
    }
  },
  methods: {
    scaleNumberBetween(
      num: number,
      from: number,
      to: number,
      min: number,
      max: number
    ) {
      if (num === 0) {
        return 0
      }
      return ((to - from) * (num - min)) / (max - min) + from
    },
    preprocessValue(value: any) {
      let { from, to } = value
      if (!from || !to) {
        const optionsValues = this.options.map(option => option.value)
        if (!from) {
          from = Math.min(...optionsValues)
        }
        if (!to) {
          to = Math.max(...optionsValues)
        }
      }
      return { from, to }
    },
    handleSliderChange(value: any) {
      this.selectedRange.from = value[0]
      this.selectedRange.to = value[1]
      this.$emit('change', {
        from: this.selectedRange.from,
        to: this.selectedRange.to
      })
    },
    createBarTitle(option) {
      const { size } = option
      if (!size) {
        return null
      }
      return `${formatNumber(option.size)}${this.sizeSuffix}`
    },
    getSizeFromValue(value) {
      const option: any = this.enhancedOptions.find(
        option => option.value === value
      )
      return option && option.size
    }
  }
})
