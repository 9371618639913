





import { defineComponent } from '~/utils/nuxt3-migration'
import CBadge from '~/components/shared/configurable/badge/CBadge.vue'
import { ciExclamationCircle } from '~/icons/source/regular/exclamation-circle'

export default defineComponent({
  components: { CBadge },
  setup() {
    return {
      ciExclamationCircle
    }
  }
})
