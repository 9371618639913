
















































































































































































































































































































































import { mapMutations, mapState } from 'vuex'
import CTransitionExpand from '~/components/shared/configurable/expand/CTransitionExpand.vue'
import RowMixin from './RowMixin'
import Title from './partials/Title'
import Location from './gallery/Location'
import KeyFeatures from './gallery/KeyFeatures'
import LastModified from './gallery/LastModified'
import CClassifiedThumbnail from '~/components/shared/configurable/classified/image/CClassifiedThumbnail.vue'
import PricePerUnit from './partials/PricePerUnit'
import Price from '~/components/car/classifieds/search/results/rows/main/partials/Price.vue'
import Category from '~/components/car/classifieds/search/results/rows/main/partials/Category.vue'
import {
  faCaretDown,
  faCog,
  faEnvelope,
  faStar
} from '@fortawesome/free-solid-svg-icons'
import InstallmentPrice from './partials/InstallmentPrice.vue'
import { CLASSIFIED_SEARCH_NS } from '~/store/modules/shared/classifieds/search/state'
import { SET_ACTIONS_ARE_COLLAPSED } from '~/store/modules/shared/classifieds/search/mutation-types'
import CClassifiedCompareButton from '~/components/shared/configurable/classified/compare/CClassifiedCompareButton.vue'
import DiscountPercentageTag from '~/components/car/classifieds/DiscountPercentageTag.vue'
import PromoRibbon from '~/components/car/classifieds/search/results/rows/main/partials/PromoRibbon.vue'
import PromoUser from '~/components/car/classifieds/search/results/rows/main/partials/PromoUser.vue'
import CBadge360 from '~/components/shared/configurable/badge/CBadge360.vue'
import {
  defineComponent,
  defineVue3AsyncComponent
} from '~/utils/nuxt3-migration'
import MarketplaceRibbon from '~/components/car/classifieds/search/results/rows/main/partials/MarketplaceRibbon.vue'

export default defineComponent({
  components: {
    CBadge360,
    BackgroundJobsBadge: defineVue3AsyncComponent(() =>
      import(
        '~/components/car/classifieds/search/results/rows/main/partials/BackgroundJobs/BackgroundJobsBadge.vue'
      )
    ),
    PaidClassifiedInfo: defineVue3AsyncComponent(() =>
      import(
        '~/components/car/classifieds/search/results/rows/main/partials/PaidInfo/PaidClassifiedInfo.vue'
      )
    ),
    PromoUser,
    PromoRibbon,
    PricePerUnit,
    LastModified,
    Title,
    KeyFeatures,
    Category,
    CClassifiedThumbnail,
    Location,
    Price,
    DiscountPercentageTag,
    CTransitionExpand,
    CClassifiedCompareButton,
    InstallmentPrice,
    MarketplaceRibbon
  },
  mixins: [RowMixin],
  data() {
    return {
      actionsOpen: false
    }
  },
  computed: {
    icons() {
      return {
        cog: faCog,
        caretDown: faCaretDown,
        envelope: faEnvelope,
        star: faStar
      }
    },
    ...mapState(CLASSIFIED_SEARCH_NS, {
      actionsAreCollapsed: state => state.actionsAreCollapsed
    }),
    shouldShowFooter() {
      return (
        (this.showAddress || this.showModificationDate) &&
        (this.classified.address ||
          this.classified.address_long ||
          this.classified.modified)
      )
    }
  },
  methods: {
    ...mapMutations(CLASSIFIED_SEARCH_NS, {
      setActionsAreCollapsed: SET_ACTIONS_ARE_COLLAPSED
    }),
    toggleActionButtons() {
      this.actionsOpen = !this.actionsOpen
      if (this.actionsOpen) {
        this.setActionsAreCollapsed(true)
      }
    },
    afterExpandClose() {
      this.setActionsAreCollapsed(false)
    }
  }
})
