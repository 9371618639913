






























import { defineComponent, ref } from '~/utils/nuxt3-migration'

export default defineComponent({
  props: {
    href: { type: String, default: null },
    to: { type: String, default: null },
    target: {
      type: String,
      default: '_self'
    },
    disabled: { type: Boolean, default: false },
    active: { type: Boolean, default: false }
  },
  setup(_, { emit }) {
    const isFocused = ref(false)
    const setFocusedStatus = (status: boolean) => {
      isFocused.value = status
    }

    const handleClick = (e: Event) => {
      emit('click', e)
    }

    return {
      isFocused,
      setFocusedStatus,
      handleClick
    }
  }
})
