import { inject } from 'tsyringe'
import { storeToken } from '~/constants/dependency-injection/tokens'
import { containerScoped } from '~/decorators/dependency-container'
import ClassifiedService from '~/services/classified/ClassifiedService'
import { CLASSIFIED_SEARCH_NS } from '~/store/modules/shared/classifieds/search/state'
import {
  MODIFY_ROW_DELETED,
  MODIFY_ROW_HIDDEN,
  MODIFY_ROW_NON_PUBLIC,
  MODIFY_ROW_PARKED,
  MODIFY_ROW_PROMOTION_ACTIVE,
  MODIFY_ROW_PUBLIC,
  MODIFY_ROW_REMOVED,
  MODIFY_ROW_RESTORED,
  MODIFY_ROW_SHOWN,
  MODIFY_ROW_TOUCHED,
  DECREMENT_PAGINATION_TOTAL
} from '~/store/modules/shared/classifieds/search/mutation-types'
import { StoreWithRootState } from '~/store/types'
import { CLASSIFIED_SEARCH_ACTIONS_NS } from '~/store/modules/shared/classifieds/search/actions/state'
import { USER_NS } from '~/store/modules/shared/user/state'
import PaidClassifiedService from '~/services/classified/paid/PaidClassifiedService'
import { SET_CREDITS } from '~/store/modules/shared/user/mutation-types'
import { ActivePromotion } from '~/models/classified/promoted'

@containerScoped()
export default class ClassifiedSearchActionsService {
  constructor(
    @inject(ClassifiedService) private classifiedService: ClassifiedService,
    @inject(storeToken) private store: StoreWithRootState,
    @inject(PaidClassifiedService)
    private paidClassifiedService: PaidClassifiedService
  ) {}

  public touchClassified(classifiedId: number) {
    return this.performStoreAction(async () => {
      const response = await this.classifiedService.touch(classifiedId)
      const { classified, user } = response.data
      const { id, modified } = classified
      const { credits } = user
      this.store.commit(`${CLASSIFIED_SEARCH_NS}/${MODIFY_ROW_TOUCHED}`, {
        classifiedId: id,
        modified
      })
      this.store.commit(`${USER_NS}/${SET_CREDITS}`, credits)
      return response
    }, classifiedId)
  }

  public deleteClassified(event: { id: number; reason?: string }) {
    const { id: classifiedId, reason } = event

    return this.performStoreAction(async () => {
      const response = await this.classifiedService.delete(classifiedId, reason)
      this.store.commit(`${CLASSIFIED_SEARCH_NS}/${MODIFY_ROW_DELETED}`, {
        classifiedId,
        deletedDate: Date.now()
      })

      return response
    }, classifiedId)
  }

  public restoreClassified(classifiedId: number) {
    return this.performStoreAction(async () => {
      const response = await this.classifiedService.restore(classifiedId)
      this.store.commit(`${CLASSIFIED_SEARCH_NS}/${MODIFY_ROW_RESTORED}`, {
        classifiedId
      })
      return response
    }, classifiedId)
  }

  public removeClassified(classifiedId: number) {
    return this.performStoreAction(async () => {
      const response = await this.classifiedService.remove(classifiedId)

      this.store.commit(`${CLASSIFIED_SEARCH_NS}/${MODIFY_ROW_REMOVED}`, {
        classifiedId
      })
      this.store.commit(`${CLASSIFIED_SEARCH_NS}/${DECREMENT_PAGINATION_TOTAL}`)
      return response
    }, classifiedId)
  }

  public hideClassifiedFromPublic(classifiedId: number) {
    return this.performStoreAction(async () => {
      const response = await this.classifiedService.hideFromPublic(
        classifiedId,
        'Κρύφτηκε απο λίστα'
      )
      this.store.commit(`${CLASSIFIED_SEARCH_NS}/${MODIFY_ROW_NON_PUBLIC}`, {
        classifiedId
      })
      return response
    }, classifiedId)
  }

  public hideClassified(classifiedId: number) {
    return this.performStoreAction(async () => {
      const response = await this.classifiedService.hide(classifiedId)
      this.store.commit(`${CLASSIFIED_SEARCH_NS}/${MODIFY_ROW_HIDDEN}`, {
        classifiedId
      })

      if (this.store.getters[`${USER_NS}/isPayingUser`]) {
        await this.paidClassifiedService.loadSearchClassifiedPaidInfo(
          classifiedId
        )
      }
      return response
    }, classifiedId)
  }

  public showClassifiedToPublic(classifiedId: number) {
    return this.performStoreAction(async () => {
      const response = await this.classifiedService.showToPublic(classifiedId)
      this.store.commit(`${CLASSIFIED_SEARCH_NS}/${MODIFY_ROW_PUBLIC}`, {
        classifiedId
      })
      return response
    }, classifiedId)
  }

  public showClassified(classifiedId: number) {
    return this.performStoreAction(async () => {
      const response = await this.classifiedService.show(classifiedId)
      this.store.commit(`${CLASSIFIED_SEARCH_NS}/${MODIFY_ROW_SHOWN}`, {
        classifiedId
      })
      if (this.store.getters[`${USER_NS}/isPayingUser`]) {
        await this.paidClassifiedService.loadSearchClassifiedPaidInfo(
          classifiedId
        )
      }
      return response
    }, classifiedId)
  }

  public setActivePromotion(promoForClassified: ActivePromotion | null) {
    this.store.commit(
      `${CLASSIFIED_SEARCH_NS}/${MODIFY_ROW_PROMOTION_ACTIVE}`,
      promoForClassified
    )
  }

  public classifiedFavoriteStateChanged({ classifiedId, isParked }: any) {
    this.store.commit(`${CLASSIFIED_SEARCH_NS}/${MODIFY_ROW_PARKED}`, {
      classifiedId,
      parked: isParked
    })
  }

  private performStoreAction(fn: () => {}, classifiedId?: number) {
    return this.store.dispatch(
      `${CLASSIFIED_SEARCH_ACTIONS_NS}/performAction`,
      { action: fn, classifiedId }
    )
  }
}
