import { RowsAds } from '~/models/ads'

export const carDesktopListRowsAds: RowsAds = {
  4: {
    google: {
      id: 'div-gpt-ad-1677154979402-0',
      path: '/297119706/gen_search_dt_top_list',
      sizes: [
        [970, 90],
        [970, 250],
        [728, 90]
      ],
      sizesMappings: [
        [
          [1344, 768],
          [
            [970, 250],
            [970, 90]
          ]
        ],
        [[768, 576], [[728, 90]]],
        [[0, 0], []]
      ],
      componentProps: {
        lazy: false,
        unit: 'in_house_search_dt_top_list'
      }
    }
  },
  14: {
    google: {
      id: 'div-gpt-ad-1677577696154-0',
      path: '/297119706/gen_search_dt_top2_list',
      sizes: [
        [970, 90],
        [970, 250],
        [728, 90]
      ],
      sizesMappings: [
        [
          [1344, 768],
          [
            [970, 250],
            [970, 90]
          ]
        ],
        [[768, 576], [[728, 90]]],
        [[0, 0], []]
      ],
      componentProps: {
        unit: 'in_house_search_dt_top2_list'
      }
    }
  }
}

export const carDesktopGalleryRowsAds: RowsAds = {
  4: {
    google: {
      id: 'div-gpt-ad-1616574457916-0',
      path: '/297119706/gen_search_dt_top_gal',
      sizes: [250, 250],
      componentProps: {
        lazy: false,
        unit: 'in_house_search_dt_top_gal'
      }
    }
  }
}

export const carMobileRowsAds: RowsAds = {
  3: {
    google: {
      path: '/297119706/gen_search_mb_top_gal',
      id: 'div-gpt-ad-1616575378050-0',
      sizes: [
        [360, 300],
        [336, 280],
        [300, 250]
      ],
      sizesMappings: [
        [
          [370, 300],
          [
            [360, 300],
            [336, 280],
            [300, 250]
          ]
        ],
        [
          [346, 280],
          [
            [336, 280],
            [300, 250]
          ]
        ],
        [[310, 250], [[300, 250]]],
        [[0, 0], []]
      ],
      componentProps: {
        lazy: false,
        unit: 'in_house_search_mb_top_gal'
      }
    }
  },
  17: {
    google: {
      path: '/297119706/gen_search_mb_mid_gal',
      id: 'div-gpt-ad-1616575411596-0',
      sizes: [
        [360, 300],
        [336, 280],
        [300, 250]
      ],
      sizesMappings: [
        [
          [370, 300],
          [
            [360, 300],
            [336, 280],
            [300, 250]
          ]
        ],
        [
          [346, 280],
          [
            [336, 280],
            [300, 250]
          ]
        ],
        [[310, 250], [[300, 250]]],
        [[0, 0], []]
      ],
      componentProps: {
        unit: 'in_house_search_mb_mid_gal'
      }
    }
  },
  24: {
    google: {
      path: '/297119706/gen_search_mb_btm_gal',
      id: 'div-gpt-ad-1616575433500-0',
      sizes: [
        [360, 300],
        [336, 280],
        [300, 250]
      ],
      sizesMappings: [
        [
          [370, 300],
          [
            [360, 300],
            [336, 280],
            [300, 250]
          ]
        ],
        [
          [346, 280],
          [
            [336, 280],
            [300, 250]
          ]
        ],
        [[310, 250], [[300, 250]]],
        [[0, 0], []]
      ],
      componentProps: {
        unit: 'in_house_search_mb_btm_gal'
      }
    }
  }
}
