


























import { mapGetters } from 'vuex'
import BaseSearchHandler from '~/components/car/classifieds/search/facets/handlers/BaseSearchHandler.vue'
import { USER_AGENT_NS } from '~/store/modules/shared/userAgent/state'
import CInputAutocomplete from '~/components/shared/configurable/form/input/CInputAutocomplete.vue'
import { faCompass } from '@fortawesome/free-solid-svg-icons'
import { mapDeps } from '~/plugins/dependency-container/utils'
import LocationSuggestionService from '~/services/location/autocomplete/LocationSuggestionService'
import { AutocompleteSuggestion } from '~/models/autocomplete'
import { Location } from '~/models/location/closest'
import ClosestLocationService from '~/services/location/closest/ClosestLocationService'
import { capitalizeSubStrings } from '~/utils/string'
import { defineComponent } from '~/utils/nuxt3-migration'
export default defineComponent({
  components: {
    CInputAutocomplete
  },
  extends: BaseSearchHandler,
  props: {
    size: { type: String, default: undefined }
  },
  data() {
    return {
      selectedLocationName: null,
      locations: new Map()
    }
  },
  computed: {
    ...mapDeps({
      locationSuggestionService: LocationSuggestionService,
      closestLocationService: ClosestLocationService
    }),
    ...mapGetters(USER_AGENT_NS, {
      isMobile: 'isMobile'
    }),
    icons: () => ({
      compass: faCompass
    }),
    locationSuggestions() {
      return [...this.locations.values()].map(v => ({
        id: v.id,
        name: v.name,
        subText: v.area
      }))
    },
    placeholder(): string {
      return capitalizeSubStrings(
        this.$t('p.c. or location').toString(),
        ['P.c', 'Τ.κ'],
        { allUppercase: true }
      )
    }
  },
  methods: {
    async onRequestSuggestions(query: string) {
      this.locations = await this.locationSuggestionService.getLocations(query)
    },
    onBackendValues({ humanParams }) {
      if (humanParams && humanParams[0]?.name) {
        this.selectedLocationName = humanParams[0].name.split('» ').reverse()[0]
      } else if (humanParams && humanParams[0]?.name) {
        this.selectedLocationName = this.locationSuggestions.get(
          humanParams[0].value.toString()
        ).name
      } else {
        this.selectedLocationName = null
      }
    },
    handleSelectedLocation(value: string) {
      this.emitParams([
        {
          name: this.defaultUrlArgName,
          value
        },
        { name: 'geolocation', value: null }
      ])
    },
    async handleCurrentLocationButtonClick() {
      try {
        const closestLocations: Location[] = await this.closestLocationService.getClosestLocationsToCurrent()
        if (!closestLocations.length) {
          return
        }
        const closestLocation = closestLocations[0]
        this.selectedLocationName = closestLocation.name
        this.locations = new Map()
        this.emitParams([
          {
            name: this.defaultUrlArgName,
            value: closestLocation.id
          },
          { name: 'geolocation', value: null }
        ])
      } catch (error) {
        this.$snackbar.error((error as Error).message)
      } finally {
        this.$refs.autocompleteInputRef.hideDropdown()
      }
    },
    onClear() {
      this.selectedLocationName = null
      this.emitParams([{ name: this.defaultUrlArgName, value: null }])
    },
    handleSuggestionSelect(suggestion: AutocompleteSuggestion) {
      this.emitParams([
        {
          name: this.defaultUrlArgName,
          value: suggestion.id
        },
        { name: 'geolocation', value: null }
      ])
    }
  }
})
