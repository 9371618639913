






























import { faShareAlt } from '@fortawesome/free-solid-svg-icons'
import CShareButtons from '~/components/shared/configurable/social/CShareButtons.vue'
import { computed, defineComponent, useRoute } from '~/utils/nuxt3-migration'
import { useId } from '~/compositions/id'
import { useAnalytics } from '~/compositions/analytics'

export default defineComponent({
  components: { CShareButtons },
  props: {
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    },
    url: {
      type: String,
      required: false,
      default: null
    }
  },
  setup() {
    const { createRandomId } = useId()
    const analytics = useAnalytics()
    const route = useRoute()

    const id = createRandomId('search-share-button')

    function handleShareButtonClick() {
      analytics.recordEvent({
        namespace: 'n_classifieds_search_share_button',
        action: 'click'
      })
    }

    const showShareButton = computed(() => {
      const isMyClassifiedsRoute =
        route.value.name === '__classifieds_search_my_classifieds'

      return !isMyClassifiedsRoute
    })

    return {
      id,
      showShareButton,
      handleShareButtonClick,
      faShareAlt
    }
  }
})
