







































































































































import {
  mapBackendSearchableToSearchable,
  mapBackendSearchableToOption,
  mapSearchableToOption
} from '~/services/search/formatters'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import CSliderBarChart, {
  SliderBarChartOption
} from '~/components/shared/configurable/chart/CSliderBarChart.vue'
import COptionWithCount from '~/components/shared/configurable/form/option/COptionWithCount.vue'
import BaseSearchHandler from '~/components/car/classifieds/search/facets/handlers/BaseSearchHandler.vue'
import { USER_AGENT_NS } from '~/store/modules/shared/userAgent/state'
import { mapGetters } from 'vuex'
import { defineComponent } from '~/utils/nuxt3-migration'

export default defineComponent({
  components: {
    CSliderBarChart,
    COptionWithCount
  },
  extends: BaseSearchHandler,
  props: {
    hideRadioButtons: {
      type: Boolean,
      default: false
    },
    hideFrom: {
      type: Boolean,
      default: false
    },
    hideTo: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectedRange: '',
      selectedLow: null,
      selectedHigh: null
    }
  },
  computed: {
    ...mapGetters(USER_AGENT_NS, {
      isPc: 'isPc'
    }),
    icons: () => ({
      chevronRight: faChevronRight
    }),
    pricesPerYearBarChartValue() {
      const { selectedLow, selectedHigh, pricesPerYearOptions } = this
      let from = selectedLow && Number(selectedLow.valueOf())
      let to = selectedHigh && Number(selectedHigh.valueOf())

      if (from > to && to !== null) {
        return
      }
      const firstOptionValue = pricesPerYearOptions[0].value
      const lastOptionValue =
        pricesPerYearOptions[pricesPerYearOptions.length - 1].value

      if (from && from < firstOptionValue) {
        from = firstOptionValue
      }
      if ((to && to > lastOptionValue) || to < firstOptionValue) {
        to = null
      }
      return { from, to }
    },
    pricesPerYearOptions(): SliderBarChartOption {
      const { prices_per_year: pricesPerYear } = this.allValues
      if (!pricesPerYear) {
        return
      }
      return pricesPerYear.map(p => {
        return {
          value: p.year,
          size: p.price
        }
      })
    },
    searchableRangesOptions() {
      return this.searchableRanges.map(mapSearchableToOption)
    },
    searchableLowHighOptions() {
      const searchables = this.searchables.values || this.searchables || []
      return searchables.map(mapBackendSearchableToOption)
    },
    searchableRanges() {
      return (this.searchables.ranges || []).map(
        mapBackendSearchableToSearchable
      )
    },
    lowName() {
      return `${this.name}-from`
    },
    highName() {
      return `${this.name}-to`
    }
  },
  methods: {
    onBackendValues({ selected }) {
      const selectedRange = (this.searchables.ranges || [])
        .map(mapBackendSearchableToSearchable)
        .find(range => range.selected)

      this.selectedLow =
        (selected.low || selected.low === 0) &&
        this.searchableLowHighOptions.some(
          o => o.value.toString() === selected.low.toString()
        )
          ? selected.low
          : null
      this.selectedHigh =
        (selected.high || selected.high === 0) &&
        this.searchableLowHighOptions.some(
          o => o.value.toString() === selected.high.toString()
        )
          ? selected.high
          : null

      this.selectedRange = selectedRange?.value || ''
    },
    onClear() {
      this.selectedRange = ''
      this.selectedLow = null
      this.selectedHigh = null
      this.emitValues()
    },
    handlePriceRangeChange({ from, to }) {
      this.emitParams([
        { name: this.defaultUrlArgName, value: null },
        { name: this.lowName, value: from },
        { name: this.highName, value: to }
      ])
    },
    handleLowChange(lowValue) {
      if (
        this.selectedHigh !== null &&
        lowValue !== null &&
        lowValue > this.selectedHigh
      ) {
        this.emitParams([
          { name: this.defaultUrlArgName, value: null },
          { name: this.lowName, value: this.selectedHigh },
          { name: this.highName, value: lowValue }
        ])
      } else {
        this.emitParams([
          { name: this.defaultUrlArgName, value: null },
          { name: this.lowName, value: lowValue },
          { name: this.highName, value: this.selectedHigh }
        ])
      }
    },
    handleHighChange(highValue) {
      if (
        this.selectedLow !== null &&
        highValue !== null &&
        highValue < this.selectedLow
      ) {
        this.emitParams([
          { name: this.defaultUrlArgName, value: null },
          { name: this.lowName, value: highValue },
          { name: this.highName, value: this.selectedLow }
        ])
      } else {
        this.emitParams([
          { name: this.defaultUrlArgName, value: null },
          { name: this.lowName, value: this.selectedLow },
          { name: this.highName, value: highValue }
        ])
      }
    },
    handleRangeChange(newRange: string) {
      if (!newRange) {
        this.onClear()
        return
      }

      if (newRange.startsWith('<')) {
        this.emitParams([
          { name: this.defaultUrlArgName, value: null },
          { name: this.lowName, value: null },
          { name: this.highName, value: Number(newRange.split('<')[1]) }
        ])
      } else if (newRange.startsWith('>')) {
        this.emitParams([
          { name: this.defaultUrlArgName, value: null },
          { name: this.lowName, value: Number(newRange.split('>')[1]) },
          { name: this.highName, value: null }
        ])
      } else if (newRange.includes('-')) {
        const [low, high] = newRange.split('-').map(Number)
        this.emitParams([
          { name: this.defaultUrlArgName, value: null },
          { name: this.lowName, value: low },
          { name: this.highName, value: high }
        ])
      } else {
        throw new TypeError('radio button range value is not supported')
      }
    },
    emitValues() {
      this.emitParams([
        { name: this.defaultUrlArgName, value: null },
        { name: this.lowName, value: this.selectedLow },
        { name: this.highName, value: this.selectedHigh }
      ])
    }
  }
})
