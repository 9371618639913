





















import CPrice from '~/components/shared/configurable/CPrice.vue'
import { defineComponent } from '~/utils/nuxt3-migration'

export default defineComponent({
  components: { CPrice },
  props: {
    installment: {
      type: [String, Number],
      required: true
    },
    financeOptions: {
      type: Object,
      required: true
    },
    withContainer: {
      type: Boolean,
      default: false
    }
  }
})
