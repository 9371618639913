































import VueSlider from 'vue-slider-component/dist-css/vue-slider-component.umd.min'
import 'vue-slider-component/dist-css/vue-slider-component.css'
import {
  computed,
  defineComponent,
  PropType,
  vue3Model
} from '~/utils/nuxt3-migration'

export default defineComponent({
  inheritAttrs: true,
  model: vue3Model,
  components: { VueSlider },
  props: {
    modelValue: {
      type: [Number, Array],
      required: true
    },
    size: {
      type: String as PropType<'md' | 'lg'>,
      default: 'md'
    },
    variant: {
      type: String as PropType<'primary' | 'secondary' | 'tbi' | 'alpha'>,
      default: 'primary'
    },
    fromLabel: {
      type: String,
      default: ''
    },
    toLabel: {
      type: String,
      default: ''
    }
  },
  setup(props, { emit }) {
    function onChange(value: number) {
      emit('change', value)
      emit('update:modelValue', value)
    }

    const isRangeMode = computed(() => Array.isArray(props.modelValue))

    return {
      isRangeMode,
      onChange
    }
  }
})
