





























import { defineComponent } from '~/utils/nuxt3-migration'

export default defineComponent({
  props: {
    minimumRentalPeriod: {
      type: Number,
      required: false,
      default: null
    },
    totalDays: {
      type: Number,
      required: true
    },
    priceForPeriod: {
      type: Number,
      required: true
    },
    priceAfterDiscount: {
      type: Number,
      required: false
    }
  }
})
