









































import { mapActions, mapMutations, mapState } from 'vuex'
import CCustomSelect from '~/components/shared/configurable/form/select/custom/CCustomSelect.vue'
import { Option } from '~/models/shared/types'
import { CLASSIFIED_SEARCH_NS } from '~/store/modules/shared/classifieds/search/state'
import { SET_PARAM } from '~/store/modules/shared/classifieds/search/mutation-types'
import {
  fallbackIcon,
  sortOptionsIcons
} from '~/constants/classified/search/sort'
import { defineComponent } from '~/utils/nuxt3-migration'

export default defineComponent({
  components: { CCustomSelect },
  props: {
    layout: {
      type: String,
      required: false,
      default: 'select'
    }
  },
  computed: {
    ...mapState({
      sortFields: state => state.classifieds.search.sortFields,
      allClassifiedsUrl: state =>
        state.classifieds.search.allClassifiedsUrl || null
    }),
    selectedSort() {
      return this.sortFields.find(field => field.selected === true) || {}
    },
    sortFieldsOptions(): Option[] {
      return this.sortFields.map(field => {
        return {
          text: field.name,
          value: field.value
        }
      })
    }
  },
  methods: {
    ...mapActions({
      search: 'classifieds/search/search'
    }),
    ...mapMutations(CLASSIFIED_SEARCH_NS, {
      setParam: SET_PARAM
    }),
    getIcon(id) {
      return (sortOptionsIcons[id] || fallbackIcon).icon
    },
    sortChanged(value) {
      this.setParam({ name: 'sort', value })
      this.setParam({ name: 'pg', value: 1 })
      this.search()
    }
  }
})
