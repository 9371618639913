









import { GoogleRowAd } from '~/models/ads'
import { mapGetters } from 'vuex'
import { CLASSIFIED_SEARCH_NS } from '~/store/modules/shared/classifieds/search/state'
import CPosting from '~/components/shared/configurable/ad/CPosting.vue'
import { defineComponent, PropType } from '~/utils/nuxt3-migration'

export default defineComponent({
  components: { CPosting },
  props: {
    ad: {
      type: Object as PropType<GoogleRowAd>,
      required: true
    }
  },
  computed: {
    ...mapGetters(CLASSIFIED_SEARCH_NS, {
      adsKeyValues: 'getAdsKeyValues'
    })
  }
})
