import { render, staticRenderFns } from "./KeyFeatures.vue?vue&type=template&id=4fc0564a&scoped=true&"
import script from "./KeyFeatures.vue?vue&type=script&lang=ts&"
export * from "./KeyFeatures.vue?vue&type=script&lang=ts&"
import style0 from "./KeyFeatures.vue?vue&type=style&index=0&id=4fc0564a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4fc0564a",
  null
  
)

export default component.exports