var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{class:{
    '!tw-bg-grey-300': _vm.isFocused,
    'tw-text-grey-500': _vm.disabled,
    'tw-bg-blue-800 !tw-text-white': _vm.active,
    'tw-cursor-pointer hover:tw-bg-grey-300': !_vm.disabled,
    'tw-px-4 tw-py-2': !(_vm.href || _vm.to)
  },on:{"click":_vm.handleClick}},[(_vm.href || _vm.to)?_c('CLink',{staticClass:"tw-block tw-px-4 tw-py-2",class:{
      '!tw-text-white hover:!tw-text-black': _vm.active,
      '!tw-text-black': !_vm.active,
      '!tw-bg-grey-300': _vm.isFocused
    },attrs:{"href":_vm.href,"to":_vm.to,"target":_vm.target,"disabled":_vm.disabled}},[_vm._t("default")],2):_c('div',[_vm._t("default")],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }