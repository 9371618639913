














import { PARKING_NS, ParkingState } from '~/store/modules/shared/parking/state'
import CClassifiedFavoriteButtonParking from '~/components/shared/configurable/classified/bucket/favorite/CClassifiedFavoriteButtonParking.vue'
import StatsService from '~/services/stats/StatsService'
import {
  computed,
  defineComponent,
  ref,
  useRoute,
  useRouter,
  watch
} from '~/utils/nuxt3-migration'
import { useDeps } from '~/compositions/dependency-container'
import { useNamespacedStore } from '~/compositions/store'
import { useSnackbar } from '~/compositions/snackbar'
import { useI18n } from '~/compositions/i18n'
import { useLogger } from '~/compositions/logger'
import { HttpStatus } from '~/constants/http'
import { useUserAgent } from '~/compositions/user-agent'
import { USER_NS, UserState } from '~/store/modules/shared/user/state'
import { ClassifiedsFavoritesService } from '~/services/ClassifiedsFavoritesService'

export default defineComponent({
  components: {
    CClassifiedFavoriteButtonParking
  },
  props: {
    showLabel: {
      type: Boolean,
      default: true
    },
    activeLabel: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    classifiedId: {
      type: [Number, String],
      required: true
    },
    parked: {
      type: Boolean,
      required: true
    },
    showNotification: {
      type: Boolean,
      required: false,
      default: true
    },
    floating: {
      type: Boolean,
      required: false,
      default: false
    },
    large: {
      type: Boolean,
      default: false
    },
    handleClickFromParent: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const [classifiedsFavoritesService, statsService] = useDeps(
      ClassifiedsFavoritesService,
      StatsService
    )
    const snackbar = useSnackbar()
    const { commit: userCommit } = useNamespacedStore<UserState>(USER_NS)
    const {
      getters: parkingGetters,
      dispatch: parkingDispatch,
      commit: parkingCommit
    } = useNamespacedStore<ParkingState>(PARKING_NS)
    const { t } = useI18n()
    const logger = useLogger()
    const route = useRoute()
    const router = useRouter()
    const { isPc } = useUserAgent()

    const isSaved = ref(props.parked)
    const favoriteButtonRef = ref(null)
    const loading = ref(false)

    const parkingShown = computed(() => parkingGetters('getShowParking'))

    const activeTab = computed(() => parkingGetters('getActiveTab'))

    const displayText = computed(() => {
      const activeLabel = props.activeLabel || t('parked')
      const label = props.label || t('park it')

      return isSaved.value ? activeLabel : label
    })

    const parkingUrl = computed(() => {
      return '/parking'
    })

    watch(
      () => props.parked,
      newVal => {
        isSaved.value = newVal
      }
    )

    async function toggleFavorite() {
      if (props.handleClickFromParent) {
        emit('parked', !isSaved.value)
        return
      }
      loading.value = true

      if (isSaved.value) {
        await removeFromFavorites()
      } else {
        await addToFavorites()
      }

      loading.value = false
    }

    async function addToFavorites() {
      try {
        await classifiedsFavoritesService.addClassifiedToFavorites(
          props.classifiedId
        )

        handleSuccess(true)

        userCommit('SET_HAS_PARKED', true)

        statsService
          .record('events.clsfds.parked', [props.classifiedId])
          .catch(() => {})
      } catch (error) {
        handleError(error)
      }
    }

    async function removeFromFavorites() {
      try {
        await classifiedsFavoritesService.removeClassifiedFromFavorites(
          props.classifiedId
        )

        handleSuccess(false)
      } catch (error) {
        handleError(error)
      }
    }

    function handleSuccess(parked: boolean) {
      if (favoriteButtonRef.value) {
        favoriteButtonRef.value.displayPopover()
      }

      if (props.showNotification) {
        showSuccessNotification(parked)
      }

      emit('parked', parked)

      // parking modal shown or we are on full parking
      if (
        parkingShown.value ||
        route.value.name.startsWith('__account_parking') ||
        route.value.name.startsWith('__parking')
      ) {
        if (activeTab.value === 'classifieds') {
          parkingDispatch('fetch_classifieds_list')
        } else if (activeTab.value === 'recent') {
          parkingCommit('TOGGLE_RECENT_PARKED', {
            classifiedId: props.classifiedId,
            parked
          })
        }
      }
    }

    function handleError(error) {
      const response = error.response
      if (!response) {
        snackbar.error(t('an error occurred please try again later'))
        logger.captureError(error)

        return
      }

      if (response.size === HttpStatus.BAD_REQUEST) {
        snackbar.error(response.data.error)
      } else {
        snackbar.error(response.data.error)
        logger.captureError(error)
      }
    }

    function showSuccessNotification(parked) {
      if (isPc.value) {
        return
      }

      const parkedText = t('added to::parking_btn')

      const removeFromParkedText = t('removed from::parking_btn')

      const actionText = t('parked::parking_btn')

      snackbar.success(parked ? parkedText : removeFromParkedText, {
        classes: [
          'action-button-as-text',
          'above-floating-button',
          '!tw-bg-blue-700'
        ],
        time: 1800,
        action: {
          text: actionText,
          callback: () =>
            router.push({
              path: parkingUrl.value
            })
        }
      })
    }

    return {
      toggleFavorite,
      removeFromFavorites,
      addToFavorites,
      isSaved,
      displayText,
      favoriteButtonRef,
      loading
    }
  }
})
