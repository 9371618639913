






























import { mapGetters } from 'vuex'
import { CLASSIFIED_SEARCH_NS } from '~/store/modules/shared/classifieds/search/state'
import { USER_AGENT_NS } from '~/store/modules/shared/userAgent/state'
import { useSearchBottomAds } from '~/compositions/ad'
import CPosting from '~/components/shared/configurable/ad/CPosting.vue'
import CGoogleAd from '~/components/shared/configurable/ad/CGoogleAd.vue'
import { defineComponent } from '~/utils/nuxt3-migration'

export default defineComponent({
  components: { CGoogleAd, CPosting },
  setup() {
    const { fullAd, leftAd, rightAd } = useSearchBottomAds()
    return { fullAd, leftAd, rightAd }
  },
  computed: {
    ...mapGetters(CLASSIFIED_SEARCH_NS, {
      adsKeyValues: 'getAdsKeyValues',
      viewTypeIsList: 'viewTypeIsList',
      inXymaSearch: 'inXymaSearch',
      isJobsSearch: 'isJobsSearch'
    }),
    ...mapGetters(USER_AGENT_NS, {
      isPc: 'isPc'
    }),
    showFullAd() {
      return this.inXymaSearch || this.isJobsSearch
    }
  }
})
