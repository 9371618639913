import { inject } from 'tsyringe'
import { containerScoped } from '~/decorators/dependency-container'
import { StoreWithRootState } from '~/store/types'
import GroupPriceSearchHandler from '~/components/car/classifieds/search/facets/handlers/GroupPriceSearchHandler.vue'
import GroupLocationSearchHandler from '~/components/car/classifieds/search/facets/handlers/GroupLocationSearchHandler.vue'
import KeyValueSearchHandler from '~/components/car/classifieds/search/facets/handlers/KeyValueSearchHandler.vue'
import ClothesColorSearchHandler from '~/components/car/classifieds/search/facets/handlers/ClothesColorSearchHandler.vue'
import SizeSearchHandler from '~/components/car/classifieds/search/facets/handlers/SizeSearchHandler.vue'
import RangeSearchHandler from '~/components/car/classifieds/search/facets/handlers/RangeSearchHandler.vue'
import ShopKeyValueSearchHandler from '~/components/car/classifieds/search/facets/handlers/ShopKeyValueSearchHandler.vue'
import FuelTypeSearchHandler from '~/components/car/classifieds/search/facets/handlers/FuelTypeSearchHandler.vue'
import TreeSearchHandler from '~/components/car/classifieds/search/facets/handlers/TreeSearchHandler.vue'
import SearchableKeyValueSearchHandler from '~/components/car/classifieds/search/facets/handlers/SearchableKeyValueSearchHandler.vue'
import InteriorColorSearchHandler from '~/components/car/classifieds/search/facets/handlers/InteriorColorSearchHandler.vue'
import ExteriorColorSearchHandler from '~/components/car/classifieds/search/facets/handlers/ExteriorColorSearchHandler.vue'
import MakeModelSearchHandler from '~/components/car/classifieds/search/facets/handlers/MakeModel/MakeModelSearchHandler.vue'
import FreeTextMakeModelSearchHandler from '~/components/car/classifieds/search/facets/handlers/FreeTextMakeModelSearchHandler.vue'
import ForMakeModelSearchHandler from '~/components/car/classifieds/search/facets/handlers/MakeModel/ForMakeModelSearchHandler.vue'
import PartNumberSearchHandler from '~/components/car/classifieds/search/facets/handlers/PartNumberSearchHandler.vue'
import GroupTireSearchHandler from '~/components/car/classifieds/search/facets/handlers/GroupTireSearchHandler.vue'
import ElectricEnginePowerSearchHandler from '~/components/car/classifieds/search/facets/handlers/ElectricEnginePowerSearchHandler.vue'
import MapLocationSearchHandler from '~/components/car/classifieds/search/facets/handlers/MapLocationSearchHandler.vue'
import ModifiedColorSearchHandler from '~/components/car/classifieds/search/facets/handlers/ModifiedColorSearchHandler.vue'
import GroupOfferTypeSearchHandler from '~/components/car/classifieds/search/facets/handlers/GroupOfferTypeSearchHandler.vue'
import FlatForMakeModelSearchHandler from '~/components/car/classifieds/search/facets/handlers/MakeModel/FlatForMakeModelSearchHandler.vue'
import GoogleRatingSearchHandler from '~/components/car/classifieds/search/facets/handlers/GoogleRatingSearchHandler.vue'
import GroupButtonsSearchHandler from '~/components/car/classifieds/search/facets/handlers/GroupButtonsSearchHandler.vue'
import PeriodSearchHandler from '~/components/car/classifieds/search/facets/handlers/PeriodSearchHandler.vue'
import PickupLocationSearchHandler from '~/components/car/classifieds/search/facets/handlers/PickupLocationSearchHandler.vue'
import { storeToken } from '~/constants/dependency-injection/tokens'

@containerScoped()
export default class ClassifiedSearchFacetsComponentsService {
  constructor(@inject(storeToken) private store: StoreWithRootState) {}

  getFacetComponent(type: string) {
    switch (type) {
      case 'OfferTypeSearchHandler': {
        return GroupOfferTypeSearchHandler
      }
      case 'PriceSearchHandler': {
        return GroupPriceSearchHandler
      }
      case 'LocationSearchHandler':
      case 'Location2SearchHandler':
      case 'DiscountLocation2SearchHandler': {
        return GroupLocationSearchHandler
      }
      case 'KeyValueSearchHandler':
      case 'BlacklistReasonSearchHandler':
      case 'TermSearchHandler':
      case 'StatesSearchHandler':
      case 'SellerTypeSearchHandler':
      case 'VehicleKteoSearchHandler':
      case 'PaidClassifiedSearchHandler':
      case 'MediaTypesSearchHandler':
      case 'VehicleFeaturesSearchHandler':
      case 'CrashedSearchHandler':
      case 'BooleanSearchHandler':
      case 'AutoTouchSearchHandler':
      case 'JobExtrasSearchHandler':
      case 'DatetimeSearchHandler':
      case 'ModifiedSearchHandler':
      case 'DeletedDateSearchHandler':
      case 'DiscountConditionSearchHandler':
      case 'DiscountSellerTypeSearchHandler':
      case 'UserTypeSearchHandler':
      case 'RentalExtrasSearchHandler':
      case 'RentalExtraServicesSearchHandler':
      case 'JobTypeSearchHandler':
      case 'AuditsSearchHandler':
      case 'AuditOptionsSearchHandler':
      case 'TradesSettingsSearchHandler': {
        return KeyValueSearchHandler
      }
      case 'ClothesColorFieldSearchHandler':
      case 'ShoesColorFieldSearchHandler': {
        return ClothesColorSearchHandler
      }
      case 'ClothesSizeFieldSearchHandler':
      case 'BicycleSizeSearchHandler':
      case 'ShoesSizeFieldSearchHandler': {
        return SizeSearchHandler
      }
      case 'RangeSearchHandler':
      case 'BatteryRangeSearchHandler':
      case 'BatteryChargeTimeSearchHandler':
      case 'BatteryQuickChargeTimeSearchHandler':
      case 'BatteryCapacitySearchHandler':
      case 'EngineSizeSearchHandler':
      case 'EnginePowerSearchHandler':
      case 'LitersSearchHandler':
      case 'LengthSearchHandler':
      case 'WeightSearchHandler':
      case 'VehicleRegistrationSearchHandler':
      case 'RentalPassengersSearchHandler':
      case 'FloorSearchHandler': {
        return RangeSearchHandler
      }
      case 'ShopKeyValueSearchHandler': {
        return ShopKeyValueSearchHandler
      }
      case 'FuelTypeSearchHandler': {
        return FuelTypeSearchHandler
      }
      case 'DiscountCategorySearchHandler':
      case 'CategoryTreeSearchHandler':
      case 'DeletedCategorySearchHandler':
      case 'AdminCategorySearchHandler': {
        return TreeSearchHandler
      }
      case 'DiscountShopKeyValueSearchHandler': {
        return SearchableKeyValueSearchHandler
      }
      case 'InteriorColorSearchHandler': {
        return InteriorColorSearchHandler
      }
      case 'ExteriorColorSearchHandler': {
        return ExteriorColorSearchHandler
      }
      case 'MakeModelSearchHandler': {
        return MakeModelSearchHandler
      }
      case 'IdentGroupsSearchHandler': {
        return () =>
          import(
            '~/components/car/classifieds/search/facets/handlers/IdentGroupsSearchHandler.vue'
          )
      }
      case 'UserKeyValueSearchHandler': {
        return () =>
          import(
            '~/components/car/classifieds/search/facets/handlers/UserKeyValueSearchHandler.vue'
          )
      }
      case 'FreeTextMakeModelSearchHandler': {
        return FreeTextMakeModelSearchHandler
      }
      case 'ForMakeModelSearchHandler': {
        if (this.store.state.classifieds.search.userOwnsSearch) {
          return FlatForMakeModelSearchHandler
        }
        return ForMakeModelSearchHandler
      }
      case 'PartNumberSearchHandler': {
        return PartNumberSearchHandler
      }
      case 'TireSearchHandler':
      case 'TireRatioSearchHandler': {
        return GroupTireSearchHandler
      }
      case 'ElectricEnginePowerSearchHandler': {
        return ElectricEnginePowerSearchHandler
      }
      case 'MapLocationSearchHandler': {
        return MapLocationSearchHandler
      }
      case 'PromotedSearchHandler': {
        return () =>
          import(
            '~/components/car/classifieds/search/facets/handlers/PromotedSearchHandler.vue'
          )
      }
      case 'SmartFreeTextSearchHandler':
      case 'FreeTextSearchHandler': {
        if (!this.store.state.classifieds.search.flags?.isMapSearch) {
          return null
        }
        return () =>
          import(
            '~/components/car/classifieds/search/facets/handlers/FreeTextSearchHandler.vue'
          )
      }
      case 'ModifiedColorSearchHandler': {
        return ModifiedColorSearchHandler
      }
      case 'GoogleRatingSearchHandler': {
        return GoogleRatingSearchHandler
      }
      case 'ConditionSearchHandler': {
        return GroupButtonsSearchHandler
      }
      case 'RentalPeriodSearchHandler': {
        return PeriodSearchHandler
      }
      case 'RentalPickupLocationSearchHandler': {
        return PickupLocationSearchHandler
      }
      default: {
        return null
      }
    }
  }
}
