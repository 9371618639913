






























































































































































import { Dealer } from '~/models/dealer/types'
import CAvatar from '~/components/shared/configurable/image/avatar/CAvatar.vue'
import Title from '~/components/car/classifieds/search/results/rows/main/partials/Title.vue'
import CGoogleRating from '~/components/shared/configurable/rating/CGoogleRating.vue'
import {
  faChevronDown,
  faChevronUp,
  faMapMarkerAlt
} from '@fortawesome/free-solid-svg-icons'
import CTransitionExpand from '~/components/shared/configurable/expand/CTransitionExpand.vue'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { CLASSIFIED_SEARCH_NS } from '~/store/modules/shared/classifieds/search/state'
import {
  SET_PAGE,
  SET_PARAM,
  SET_SHOW_SELLERS
} from '~/store/modules/shared/classifieds/search/mutation-types'
import { USER_NS } from '~/store/modules/shared/user/state'
import { USER_AGENT_NS } from '~/store/modules/shared/userAgent/state'
import { CLASSIFIED_VIEW_NS } from '~/store/modules/shared/classifieds/view/state'
import { SearchSellerRowClassified } from '~/models/search/types'
import { createPhotoUrlWithSize } from '~/utils/photos'
import {
  defineComponent,
  PropType,
  defineVue3AsyncComponent
} from '~/utils/nuxt3-migration'

export default defineComponent({
  components: {
    CAvatar,
    Title,
    CGoogleRating,
    CGoogleMapEmbed: defineVue3AsyncComponent(() =>
      import('~/components/shared/configurable/map/CGoogleMapEmbed.vue')
    ),
    CHybridTelephone: defineVue3AsyncComponent(() =>
      import('~/components/shared/configurable/telephone/CHybridTelephone.vue')
    ),
    CTransitionExpand,
    DealerStats: defineVue3AsyncComponent(() =>
      import(
        '~/components/car/classifieds/search/results/rows/main/partials/DealerStats.vue'
      )
    ),
    DirectionsButton: defineVue3AsyncComponent(() =>
      import('~/components/car/maps/DirectionsButton.vue')
    )
  },
  props: {
    seller: {
      type: Object as PropType<Dealer>,
      required: true
    }
  },
  data() {
    return {
      expanded: false
    }
  },
  computed: {
    ...mapGetters(USER_AGENT_NS, {
      isPc: 'isPc',
      isMobile: 'isMobile'
    }),
    ...mapState(USER_NS, {
      currentUserId: state => state.id
    }),
    showFromLabelInDealerStats() {
      return this.seller.classifieds.length > 1
    },
    isOwn() {
      return this.seller.managerId === this.currentUserId
    },
    icons() {
      return {
        chevronDown: faChevronDown,
        chevronUp: faChevronUp,
        marker: faMapMarkerAlt
      }
    }
  },
  methods: {
    ...mapActions(CLASSIFIED_VIEW_NS, {
      navigateToClassifiedView: 'navigateToClassifiedView'
    }),
    ...mapActions(CLASSIFIED_SEARCH_NS, {
      search: 'search'
    }),
    ...mapMutations(CLASSIFIED_SEARCH_NS, {
      setParam: SET_PARAM,
      setShowSellers: SET_SHOW_SELLERS,
      setPage: SET_PAGE
    }),
    handleClassifiedClick(
      evt: MouseEvent,
      classified: SearchSellerRowClassified
    ) {
      const { seoUrl, thumbPattern } = classified
      const imageUrl = this.createImageUrl(thumbPattern)

      const refs = {
        id: classified.id,
        isParked: false,
        thumb: imageUrl,
        title: classified.title,
        price: classified.price,
        thumbIndex: 0,
        offertype: '',
        hasPhotos: classified.hasPhotos,
        thumbsLength: 0,
        externalLink: null
      }
      if (this.$listeners['classified-click']) {
        this.$emit('classified-click', { classified, e: evt, thumbData: {} })
      } else {
        this.navigateToClassifiedView({ evt, url: seoUrl, setSp: true, refs })
      }
    },
    createImageUrl(thumbPattern: string) {
      return createPhotoUrlWithSize(thumbPattern, 'n')
    },
    selectSeller() {
      this.setParam({ name: 'shop', value: this.seller.id })
      this.setPage(1)
      this.setShowSellers(false)
      this.search({
        updateUrl: true,
        pageView: true
      })
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
      this.$emit('select-seller')
    },
    toggleCollapse() {
      this.expanded = !this.expanded
      this.$emit('toggle-collapse', this.expanded)
    },
    closeCollapse() {
      this.expanded = false
    }
  }
})
