







import {
  CLASSIFIED_SEARCH_NS,
  SearchState
} from '~/store/modules/shared/classifieds/search/state'
import Search from '~/components/car/classifieds/search/Search.vue'
import SearchShimmer from '~/components/car/classifieds/search/shimmers/SearchShimmer.vue'
import SearchService from '~/services/search/SearchService'
import RouteGuardService from '~/services/RouteGuardService'
import {
  computed,
  defineComponent,
  onBeforeUnmount,
  useStore
} from '~/utils/nuxt3-migration'
import { useNamespacedStore } from '~/compositions/store'
import { USER_NS } from '~/store/modules/shared/user/state'

export default defineComponent({
  name: 'BaseSearchPage',
  components: { Search, SearchShimmer },
  middleware({ $dep, route, redirect }) {
    if (
      (route.name?.startsWith('__classifieds_search_my_classifieds') ||
        route.name?.startsWith('__classifieds_search_my_classifieds_seo')) &&
      !$dep(RouteGuardService).userIsLoggedIn()
    ) {
      redirect(`/login?gotonext=${route.path}`)
    }
  },
  // TODO: investigate if this causes performance issues
  watchQuery(newQuery, oldQuery) {
    delete newQuery.sp
    delete oldQuery.sp
    return JSON.stringify(newQuery) !== JSON.stringify(oldQuery)
  },
  async asyncData({ route, error, $dep }) {
    const searchService = $dep(SearchService)
    await searchService.createSearch({
      route,
      errorFn: error,
      mapSearch: false
    })
  },
  setup() {
    const { state } = useNamespacedStore<SearchState>(CLASSIFIED_SEARCH_NS)
    const store = useStore()

    const searchId = computed(() => state.searchId)

    const unsubscribe = store.subscribeAction({
      after: action => {
        if (!process.client) {
          return
        }
        if (action.type === `${USER_NS}/loadUserSubscribable`) {
          window.location.reload()
        }
      }
    })

    onBeforeUnmount(() => {
      unsubscribe()
    })

    return {
      searchId
    }
  },
  head() {
    const searchService = this.$dep(SearchService)
    return searchService.createSearchHead()
  }
})
