














import { mapState } from 'vuex'
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons'
import { defineComponent } from '~/utils/nuxt3-migration'

export default defineComponent({
  data() {
    return {
      icons: {
        pencil: faPencilAlt
      }
    }
  },
  computed: {
    ...mapState({
      changeSearchUrl: state => state.classifieds.search.changeSearchUrl
    })
  }
})
