
































import CPrice from '~/components/shared/configurable/CPrice.vue'
import {
  computed,
  defineComponent,
  PropType,
  toRefs
} from '~/utils/nuxt3-migration'
import {
  ClassifiedDiscount,
  ClassifiedPayment,
  ClassifiedSalary
} from '~/models/classified/types'

export default defineComponent({
  components: { CPrice },
  props: {
    priceRelatedText: {
      type: String,
      default: null
    },
    prefix: {
      type: String,
      default: null
    },
    small: {
      type: Boolean,
      default: false
    },
    suffix: {
      type: String,
      default: ''
    },
    price: {
      type: [String, Number],
      default: null
    },
    payment: {
      type: [Object, null] as PropType<ClassifiedPayment | null>,
      required: false,
      default: null
    },
    salary: {
      type: [Object, null] as PropType<ClassifiedSalary | null>,
      required: false,
      default: null
    },
    discount: {
      type: [Object, null] as PropType<ClassifiedDiscount | null>,
      required: false,
      default: null
    }
  },
  setup(props) {
    const { price, payment, salary, prefix } = toRefs(props)

    const formattedPayment = computed(() => {
      if (!payment.value) {
        return ''
      }

      return payment.value.label
        ? `${payment.value.value} ${payment.value.label}`
        : payment.value.value
    })

    const formattedSalary = computed(() => {
      if (!salary.value) {
        return ''
      }

      return `${salary.value.value} / ${salary.value.label}`
    })

    const displayValue = computed(() => {
      return price.value || formattedPayment.value || formattedSalary.value
    })

    const isLong = computed(() => {
      return (
        (prefix.value?.length || 0) + (displayValue.value?.length || 0) > 10
      )
    })

    return { isLong, displayValue, formattedPayment, formattedSalary }
  }
})
