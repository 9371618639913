




































import { faStar, faStarHalfAlt } from '@fortawesome/free-solid-svg-icons'
import { faStar as farStar } from '@fortawesome/free-regular-svg-icons/faStar'
import { defineComponent } from '~/utils/nuxt3-migration'

export default defineComponent({
  props: {
    googleRating: {
      type: Object,
      required: true
    },
    dealer: {
      type: Object,
      required: false,
      default() {
        return null
      }
    }
  },
  computed: {
    icons() {
      return { star: faStar, starHalfAlt: faStarHalfAlt, rStar: farStar }
    },
    fullStars() {
      return Math.floor(this.googleRating.rating_normalized)
    },
    hasHalfStar() {
      return this.googleRating.rating_normalized % 1 !== 0
    },
    emptyStars() {
      if (this.hasHalfStar) {
        return 5 - (this.fullStars + 1)
      } else return 5 - this.fullStars
    },
    getUrl() {
      if (this.dealer) {
        return this.dealer.page_url + '/contact?bl=1'
      }

      return this.googleRating.url
    }
  }
})
