var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{ 'tw-min-h-[calc(100vh_-_6rem)]': _vm.isMobile }},[_c('Header'),_vm._v(" "),_c('MobileTopBar'),_vm._v(" "),_c('div',{staticClass:"tw-mt-2 tw-text-center tw-tw-block lg:tw-hidden"},[(_vm.isWantedPartsSearch)?_c('AutoRefreshButton'):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"tw-flex tw-justify-between"},[(_vm.userIsAdmin && _vm.showAdminMassActions && !_vm.showSellers)?_c('AdminMassActions',{staticClass:"tw-mt-2"}):_vm._e()],1),_vm._v(" "),(_vm.inTaxisSearch)?_c('TaxiPermitsAlert'):_vm._e(),_vm._v(" "),(_vm.showScamWarningAlert)?_c('ScamWarningAlert'):_vm._e(),_vm._v(" "),_c('COverlay',{staticClass:"rows-wrapper",class:{
      loading: _vm.loadingResults
    },attrs:{"no-fade":"","show":_vm.loadingResults,"spinner-variant":"primary","spinner-size":"lg"}},[_c('LazyHydrate',{attrs:{"when-idle":""}},[(_vm.showSellers)?[(_vm.sellerRows && _vm.sellerRows.length !== 0)?_c('RowsContainer',{staticClass:"tw-mt-2",attrs:{"view-type":"list"}},[_vm._l((_vm.sellerRows),function(seller,index){return [(_vm.shouldDisplayAds && _vm.sellersAds[index])?_c('RowAd',{key:(_vm.viewType + "-" + index + "-" + _vm.currentPage),attrs:{"ad":_vm.sellersAds[index]}}):_vm._e(),_vm._v(" "),_c('li',{key:seller.id},[_c('SellerRow',{ref:("sellerRow" + (seller.id)),refInFor:true,attrs:{"seller":seller},on:{"toggle-collapse":function($event){return _vm.sellerCollapseToggled(seller.id, $event)}}})],1)]})],2):(!_vm.loadingResults)?_c('NoResultsFound'):_c('div',{staticClass:"results-pending-client-side"})]:[(_vm.rows && _vm.rows.length > 0)?_c('RowsContainer',{ref:"rowsContainer",staticClass:"tw-mt-2",class:{
            'md:tw-grid-cols-2 tw-gap-6': _vm.isWantedPartsSearch
          },attrs:{"view-type":_vm.isWantedPartsSearch ? null : _vm.viewType,"gallery-lg-per-row":_vm.galleryLgPerRow}},[_vm._l((_vm.rows),function(classified,index){return [(_vm.shouldDisplayAds && _vm.rowsAds[index])?_c('RowAd',{key:(_vm.viewType + "-" + index),attrs:{"ad":_vm.rowsAds[index]}}):_vm._e(),_vm._v(" "),_c('li',{key:classified.id},[(index === 10 && _vm.isMobile)?_c('div',{key:("scroller-" + index),staticClass:"tw-flex tw-justify-center tw-w-full tw-max-w-full tw-min-w-0 tw-overflow-hidden",attrs:{"id":"scroller-entry"}}):_vm._e(),_vm._v(" "),_c(_vm.RowComponent,{key:classified.id,tag:"component",staticClass:"swipable",attrs:{"classified":classified,"index":index,"rows-extra-query-params":_vm.rowsExtraQueryParams,"lazy-load-image":index > 8,"rows-target":_vm.rowsTarget,"show-address":_vm.showAddress,"show-modification-date":_vm.showModificationDate,"show-action-disable-buttons":_vm.showDisableButtons},on:{"park":function($event){return _vm.classifiedSearchActionsService.classifiedFavoriteStateChanged(
                    $event
                  )},"delete":function($event){return _vm.classifiedSearchActionsService.deleteClassified($event)},"touch":function($event){return _vm.classifiedSearchActionsService.touchClassified($event)},"restore":function($event){return _vm.classifiedSearchActionsService.restoreClassified($event)},"remove":function($event){return _vm.classifiedSearchActionsService.removeClassified($event)},"hide-from-public":function($event){return _vm.classifiedSearchActionsService.hideClassifiedFromPublic(
                    $event
                  )},"show-to-public":function($event){return _vm.classifiedSearchActionsService.showClassifiedToPublic(
                    $event
                  )},"hide":function($event){return _vm.classifiedSearchActionsService.hideClassified($event)},"show":function($event){return _vm.classifiedSearchActionsService.showClassified($event)},"set-active-promotion":function($event){return _vm.classifiedSearchActionsService.setActivePromotion($event)}}})],1)]})],2):(_vm.rows && _vm.rows.length === 0)?_c('NoResultsFound',{staticClass:"lg:!tw-my-12 tw-my-4"}):_c('div',{staticClass:"results-pending-client-side"})]],2),_vm._v(" "),(_vm.showSellers && !_vm.loadingResults)?_c('PrivateSellersRecommendation'):_vm._e()],1),_vm._v(" "),_c('Footer'),_vm._v(" "),(_vm.rows && _vm.shouldDisplayAds)?_c('Bottom'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }