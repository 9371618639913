import { USER_AGENT_NS } from '~/store/modules/shared/userAgent/state'
import { CLASSIFIED_SEARCH_NS } from '~/store/modules/shared/classifieds/search/state'
import { GoogleAd, RowsAds } from '~/models/ads'
import { computed, useStore } from '~/utils/nuxt3-migration'
import { Ref } from '@vue/composition-api'
import {
  carDesktopGalleryRowsAds,
  carDesktopListRowsAds,
  carMobileRowsAds
} from '~/constants/ads/search/rows/car'
import {
  carMapSearchDesktopBottomFullAd,
  carSearchBottomGalleryAd1,
  carSearchBottomGalleryAd2,
  carSearchDesktopBottomFullAd,
  carSearchMobileBottomAd
} from '~/constants/ads/search/search'

export function useSearchRowsAds(): {
  rowsAds: Ref<RowsAds>
  sellersAds: Ref<RowsAds>
} {
  const store = useStore()

  const isPc = computed(
    () =>
      store.getters[`${USER_AGENT_NS}/isPc`] ||
      store.getters[`${USER_AGENT_NS}/isTablet`]
  )

  const viewTypeIsList = computed(
    () => store.getters[`${CLASSIFIED_SEARCH_NS}/viewTypeIsList`]
  )

  const mobileRowsAds = computed(() => {
    return carMobileRowsAds
  })

  const desktopGalleryRowsAds = computed(() => {
    return carDesktopGalleryRowsAds
  })

  const desktopListRowsAds = computed(() => {
    return carDesktopListRowsAds
  })

  const rowsAds = computed(() => {
    if (isPc.value) {
      if (viewTypeIsList.value) {
        return desktopListRowsAds.value
      } else {
        return desktopGalleryRowsAds.value
      }
    } else {
      return mobileRowsAds.value
    }
  })

  const sellersAds = computed(() => {
    if (isPc.value) {
      return desktopListRowsAds.value
    } else {
      return mobileRowsAds.value
    }
  })

  return { rowsAds, sellersAds }
}

export function useSearchBottomAds(): {
  fullAd: Ref<GoogleAd>
  leftAd: Ref<GoogleAd>
  rightAd: Ref<GoogleAd>
} {
  const store = useStore()

  const isPc = computed(() => store.getters[`${USER_AGENT_NS}/isPc`])

  const desktopFullAd = computed(() => {
    return carSearchDesktopBottomFullAd
  })
  const mobileFullAd = computed(() => {
    return carSearchMobileBottomAd
  })

  const leftAd = computed(() => {
    return carSearchBottomGalleryAd1
  })

  const rightAd = computed(() => {
    return carSearchBottomGalleryAd2
  })

  const fullAd = computed(() => {
    if (isPc.value) {
      return desktopFullAd.value
    } else {
      return mobileFullAd.value
    }
  })

  return { fullAd, leftAd, rightAd }
}

export function useMapSearchBottomAds(): {
  fullAd: Ref<GoogleAd>
} {
  const fullAd = computed(() => {
    return carMapSearchDesktopBottomFullAd
  })

  return { fullAd }
}
