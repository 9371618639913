




































import { defineComponent } from '~/utils/nuxt3-migration'
import BaseSearchHandler from '~/components/car/classifieds/search/facets/handlers/BaseSearchHandler.vue'
import { addYears, format, parseDate } from '~/utils/date'
import CDatePicker from '~/components/shared/configurable/date/CDatePicker.vue'

const DATE_FORMAT = 'd/M/yyyy'

interface Data {
  dateFrom: Date | null
  dateTo: Date | null
}

export default defineComponent({
  extends: BaseSearchHandler,
  components: { CDatePicker },
  data(): Data {
    return {
      dateFrom: null,
      dateTo: null
    }
  },
  computed: {
    datepickerFromPlaceholder() {
      return this.$t('from')
    },
    datepickerToPlaceholder() {
      return this.$t('to')
    },
    datepickerLocale() {
      return this.$i18n.locale
    },
    datepickerFromMinDate() {
      return this.today.toISOString()
    },
    datepickerFromMaxDate() {
      return this.oneYearLater?.toISOString()
    },
    datepickerToMaxDate() {
      return this.oneYearLater?.toISOString()
    },
    datepickerToMinDate() {
      return this.dateFrom?.toISOString() || this.today?.toISOString()
    },
    today(): Date {
      return new Date()
    },
    oneYearLater(): Date {
      return addYears(this.today, 1)
    }
  },
  methods: {
    onClear() {
      this.emitParams([
        {
          name: `${this.defaultUrlArgName}-from`,
          value: null
        },
        {
          name: `${this.defaultUrlArgName}-to`,
          value: null
        }
      ])
    },
    onBackendValues({ selected }: any) {
      this.dateFrom =
        (selected.low && parseDate(selected.low, DATE_FORMAT)) || selected.low
      this.dateTo =
        (selected.high && parseDate(selected.high, DATE_FORMAT)) ||
        selected.high
    },
    handleDateFromInput(date: Date) {
      this.emitParams([
        {
          name: `${this.defaultUrlArgName}-from`,
          value: format(date, DATE_FORMAT)
        }
      ])
    },
    handleDateToInput(date: Date) {
      this.emitParams([
        {
          name: `${this.defaultUrlArgName}-to`,
          value: format(date, DATE_FORMAT)
        }
      ])
    }
  }
})
