























import { defineNuxtComponent } from '~/utils/nuxt3-migration'
import { useCookies } from '~/compositions/useCookies'
import { THREE_MONTHS } from '~/constants/duration'
import { defineComponentTranslations } from '~/utils/i18n'

export default defineNuxtComponent({
  setup() {
    const cookies = useCookies()

    const scamWarningCookieExists = !!cookies.get('scamWarning')

    function setScamWarningCookie() {
      cookies.set('scamWarning', 1, { maxAge: THREE_MONTHS })
    }

    return {
      scamWarningCookieExists,
      setScamWarningCookie
    }
  },
  i18n: defineComponentTranslations({
    scam_warning_message: {
      en: 'In SMS & emails that aim to steal personal information.',
      el: 'Σε SMS & email που στοχεύουν να αποσπάσουν προσωπικά στοιχεία.'
    }
  })
})
