
















import { mapGetters, mapState } from 'vuex'
import { faPlusSquare } from '@fortawesome/free-solid-svg-icons'
import { mapDeps } from '~/plugins/dependency-container/utils'
import { LegacyUrlService } from '~/services/legacy/url/LegacyUrlService'
import RouteGuardService from '~/services/RouteGuardService'
import { USER_NS } from '~/store/modules/shared/user/state'
import { defineComponent } from '~/utils/nuxt3-migration'

export default defineComponent({
  data() {
    return {
      icons: {
        plusSquare: faPlusSquare
      }
    }
  },
  computed: {
    ...mapGetters(USER_NS, {
      isAdmin: 'isAdmin'
    }),
    ...mapState({
      newClassifiedUrl: state => state.classifieds.search.newClassifiedUrl
    }),
    ...mapDeps({
      legacyUrlService: LegacyUrlService,
      routeGuardService: RouteGuardService
    }),
    showOiriginal() {
      return (
        this.newClassifiedUrl &&
        this.routeGuardService.userCanCreateClassified() &&
        !this.isAdmin
      )
    }
  }
})
