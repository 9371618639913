































import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { CLASSIFIED_SEARCH_NS } from '~/store/modules/shared/classifieds/search/state'
import { capitalizeSentences } from '~/utils/string'
import {
  SET_PAGE,
  SET_SHOW_SELLERS
} from '~/store/modules/shared/classifieds/search/mutation-types'
import { formatNumber } from '~/utils/number'
import CSpinner from '~/components/shared/configurable/CSpinner.vue'
import { defineComponent } from '~/utils/nuxt3-migration'

export default defineComponent({
  components: { CSpinner },
  computed: {
    ...mapState(CLASSIFIED_SEARCH_NS, {
      loadingResults: state => state.loading.results,
      loadingFacets: state => state.loading.facets,
      totalSellersClassifieds: state => state.sellersClassifiedsInfo?.total,
      isFiltersPage: state => state.config.settings.isFiltersPage,
      total: state => state.stats?.total
    }),
    ...mapGetters(CLASSIFIED_SEARCH_NS, {
      totalRows: 'getTotalRows',
      showSellers: 'showSellers',
      facetsCount: 'getFacetsCount'
    }),
    resultsText() {
      let total = 0
      if (this.isFiltersPage) {
        total = formatNumber(this.total)
      } else if (this.showSellers) {
        total = this.totalSellersClassifieds
      } else {
        total = formatNumber(this.totalRows)
      }
      return capitalizeSentences(
        this.$tc('show 1 classified | show {count} classifieds', total)
      )
    }
  },
  methods: {
    ...mapActions(CLASSIFIED_SEARCH_NS, {
      toggleMobileFacets: 'toggleMobileFacets',
      search: 'search',
      clearAll: 'clearAll',
      refreshAds: 'refreshAds'
    }),
    ...mapMutations(CLASSIFIED_SEARCH_NS, {
      setPage: SET_PAGE,
      setShowSellers: SET_SHOW_SELLERS
    }),
    handleClear() {
      this.clearAll()
    },
    showButtonClick(e) {
      if (this.$listeners.click) {
        this.$emit('click', e)
        return
      }
      if (this.showSellers) {
        // Special handling for when the user has the sidebar open but was viewing shops
        this.setPage(1)
        this.setShowSellers(false)
        this.search()
      }
      this.toggleMobileFacets()

      this.refreshAds()
    }
  }
})
