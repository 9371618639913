var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-relative"},[_c('CButton',{staticClass:"compare-btn",class:{
      'tw-bg-transparent': !_vm.bgFill,
      '!tw-text-grey-600': !_vm.isActive && !_vm.bgFill,
      active: _vm.isActive && !_vm.bgFill,
      '!tw-text-blue-800': !_vm.isActive && _vm.bgFill,
      '!tw-bg-blue-800 !tw-text-white': _vm.bgFill && _vm.isActive,
      'icon-only': _vm.iconOnly,
      'pc hover:!tw-text-white': _vm.isPc
    },style:({
      width: (_vm.size + "px"),
      height: (_vm.size + "px")
    }),attrs:{"disable-hover":"","title":_vm.buttonTitle,"variant":"primary","outline":"","size":"sm"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.handleButtonClick($event)}}},[_c('fa',{attrs:{"icon":_vm.faCompressAlt}}),_vm._v(" "),_c('div',{staticClass:"tw-left-1/2 tw-w-px tw-h-px tw-top-0 tw-absolute"})],1),_vm._v(" "),(_vm.isPc && _vm.isPopoverVisible)?_c('CDropdown',{staticClass:"tw-border-0",attrs:{"shown":_vm.isPopoverVisible,"triggers":['click'],"delay":"0","distance":50,"placement":"top"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('CLink',{staticClass:"comparison-link",attrs:{"to":_vm.hasCompareUrlIds ? _vm.compareUrl : null}},[_c('span',{staticClass:"prefix"},[_vm._v("\n          "+_vm._s(_vm.isActive ? _vm.$t('added to') : ((_vm.$t('removed from')) + " "))+"\n        ")]),_vm._v(" "),_c('span',{class:_vm.hasCompareUrlIds ? 'tw-underline' : 'tw-text-body'},[_vm._v("\n          "+_vm._s(_vm.$t('comparison').toLowerCase())+"\n        ")])])]},proxy:true}],null,false,4216005135)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }