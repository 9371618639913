import { AxiosResponse } from 'axios'
import { inject } from 'tsyringe'
import { VueRouter } from '~/utils/nuxt3-migration'
import { containerScoped } from '~/decorators/dependency-container'
import { Compare } from '~/models/compare'
import { StoreWithRootState } from '~/store/types'
import { toCamelCase } from '~/utils/object'
import RequestBuilder from '~/builders/http/RequestBuilder'
import {
  storeToken,
  vueRouterToken
} from '~/constants/dependency-injection/tokens'

@containerScoped()
export default class CompareService {
  constructor(
    @inject(vueRouterToken) private router: VueRouter,
    @inject(storeToken) private store: StoreWithRootState,
    @inject(RequestBuilder) private requestBuilder: RequestBuilder
  ) {}

  fetchComparableItems(ids: string[] = []): Promise<Compare> {
    return this.requestBuilder
      .request('get', '/api/classifieds/compare/')
      .params({ id: ids })
      .map((body: any) => toCamelCase(this.normalizeData(body)))
      .send()
  }

  private normalizeData(body: AxiosResponse): Compare {
    const { data } = body
    const { results } = data
    const numOfItems = results.num_of_items
    delete results.num_of_items
    const keys = Object.keys(results)
    const group: any = { itemSpecs: [] }
    const itemSpecs: Array<{ label: string; specs: Array<any> }> = []
    keys.forEach(k => {
      const { fields } = results[k]
      if (fields) {
        itemSpecs.push({ label: k, specs: this.groupFields(fields) })
      } else {
        group[k] = results[k]
      }
    })
    group.itemSpecs = itemSpecs

    return {
      results: group,
      comparableCategories: data.comparable_categories,
      numOfCategories: data.num_of_categories,
      rejected: data.rejected,
      category: data.category,
      numOfItems
    }
  }

  async setComparedClassified(
    categoryId: number | string,
    id: number
  ): Promise<any> {
    const compare = this.store.getters['storage/getItem']('compare') || {}
    let categoryClassifieds: Array<number> = []
    if (categoryId && Object.keys(compare).length && compare[categoryId]) {
      categoryClassifieds = compare[categoryId].slice()
    }

    if (categoryClassifieds.find(c => c === id)) {
      categoryClassifieds = categoryClassifieds.filter(c => c !== id)
    } else {
      categoryClassifieds.push(id)
    }
    await this.store.dispatch('storage/set', {
      compare: { [categoryId]: categoryClassifieds }
    })
  }

  deleteComparedCategory(categoryId: number) {
    const compare = { ...this.store.getters['storage/getItem']('compare') }
    compare[categoryId] = []

    this.store.dispatch('storage/set', { compare: { ...compare } })
  }

  createCompareUrl(currentCategoryId: number): string {
    const compare = this.store.getters['storage/getItem']('compare') || {}
    const ids = compare[currentCategoryId]
    return this.router.resolve({
      name: '__compare',
      query: {
        id: ids
      }
    }).href
  }

  private groupFields(fields: any): Array<Array<{ key: string; value: any }>> {
    const fieldsArray: any = []
    const fieldKeys = Object.keys(fields).sort()
    fieldKeys.forEach(k => {
      fieldsArray.push({ key: k, value: fields[k] })
    })
    return fieldsArray
  }
}
