





import { defineComponent } from '~/utils/nuxt3-migration'
import CBadge from '~/components/shared/configurable/badge/CBadge.vue'

export default defineComponent({
  components: { CBadge }
})
