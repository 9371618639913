










import { defineComponent, vue3Model } from '~/utils/nuxt3-migration'

export default defineComponent({
  model: vue3Model,
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    value: {
      type: [String, Number],
      required: true
    },
    modelValue: {
      type: Array,
      required: false,
      default() {
        return []
      }
    }
  },
  computed: {
    isChecked() {
      return this.modelValue.includes(this.value)
    }
  },
  methods: {
    handleClick() {
      if (this.isChecked) {
        this.emitValue(this.modelValue.filter(value => value !== this.value))
      } else {
        this.emitValue([...this.modelValue, ...[this.value]])
      }
    },
    emitValue(value) {
      this.$emit('update:modelValue', value)
    }
  }
})
