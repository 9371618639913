

















import { defineComponent, ref } from '~/utils/nuxt3-migration'
import { useDep } from '~/compositions/dependency-container'
import { useI18n } from '~/compositions/i18n'
import { useLogger } from '~/compositions/logger'
import { useSnackbar } from '~/compositions/snackbar'
import { useNamespacedStore } from '~/compositions/store'
import SearchBucketService from '~/services/search-bucket/SearchBucketService'
import {
  CLASSIFIED_SEARCH_NS,
  SearchState
} from '~/store/modules/shared/classifieds/search/state'
import { defineComponentTranslations } from '~/utils/i18n'
export default defineComponent({
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  setup(_, { emit }) {
    const name = ref('')
    const { state } = useNamespacedStore<SearchState>(CLASSIFIED_SEARCH_NS)
    const snackbar = useSnackbar()
    const logger = useLogger()
    const { t } = useI18n()
    const searchBucketService = useDep(SearchBucketService)

    const handleClose = () => {
      name.value = ''
      emit('toggle', false)
    }
    const handleSubmitName = async () => {
      try {
        if (state.searchId) {
          await searchBucketService.editFavoriteSearch(state.searchId, {
            name: name.value
          })
          snackbar.success(t('search name has been saved'))
          handleClose()
        }
      } catch (err) {
        logger.captureError(`${err}`)
      }
    }

    return { name, handleSubmitName, handleClose }
  },
  i18n: defineComponentTranslations({
    select_favorite_search_name: {
      en: 'Select favorite search name',
      el: 'Επίλεξε όνομα αγαπημένης αναζήτησης'
    }
  })
})
