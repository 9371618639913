




















































































import { mapBackendSearchableToOption } from '~/services/search/formatters'
import COptionWithCount from '~/components/shared/configurable/form/option/COptionWithCount.vue'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { textExistsInString } from '~/utils/string'
import ExpandableHandlerContent from '~/components/car/classifieds/search/facets/handlers/ExpandableHandlerContent.vue'
import BaseSearchHandler from '~/components/car/classifieds/search/facets/handlers/BaseSearchHandler.vue'
import CTreeSelect from '~/components/shared/configurable/form/select/tree/CTreeSelect.vue'
import { defineComponent } from '~/utils/nuxt3-migration'

export default defineComponent({
  components: {
    COptionWithCount,
    ExpandableHandlerContent,
    CTreeSelect
  },
  extends: BaseSearchHandler,
  props: {
    isQuickSearch: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      q: '',
      selectedValue: null,
      selectedValues: []
    }
  },
  computed: {
    icons: () => ({
      search: faSearch
    }),
    isSearchable() {
      return this.type === 'VehicleFeaturesSearchHandler'
    },
    filteredOptions() {
      if (this.q && this.q.length > 0) {
        return this.options.filter(option =>
          textExistsInString(this.q, option.text)
        )
      }
      return this.options
    },
    options() {
      if (this.searchables?.length) {
        return (
          this.searchables && this.searchables.map(mapBackendSearchableToOption)
        )
      }
      return []
    }
  },
  methods: {
    onClearAll() {
      this.q = ''
    },
    onClear() {
      this.selectedValue = null
      this.selectedValues = null
      this.emitParams([{ name: this.defaultUrlArgName, value: null }])
    },
    onBackendValues({ selected }) {
      this.selectedValue = selected && Array.isArray(selected) && selected[0]
      this.selectedValues = selected
    },
    handleValueChange(value) {
      this.emitParams([{ name: this.defaultUrlArgName, value }])
      if (this.q) {
        // just clicked in the one result from search, so reset search
        this.q = ''
      }
    },
    treeChanged(newValues: any[]) {
      this.selectedValues = newValues
      this.emitParams([{ name: this.defaultUrlArgName, value: newValues }])
    },
    handleRedirectUrlClick(redirectUrl: string) {
      this.loadSeoUrlSearch({
        seoUrl: redirectUrl
      })
    }
  }
})
