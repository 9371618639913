
import BaseSearchHandler from '~/components/car/classifieds/search/facets/handlers/BaseSearchHandler.vue'
import {
  mapBackendSearchableToOption,
  mapBackendSearchableToSearchable,
  mapSearchableToOption
} from '~/services/search/formatters'
import {
  faChevronDown,
  faChevronUp,
  faInfoCircle
} from '@fortawesome/free-solid-svg-icons'
import {
  MAKE_MODELS_REGISTRATION_SEARCH_PARAM,
  MAKE_MODELS_STRICT_SEARCH_PARAM,
  MAKES_IDS_SEARCH_PARAM,
  MAKES_SEARCH_PARAM,
  MODELS_IDS_SEARCH_PARAM,
  MODELS_SEARCH_PARAM
} from '~/constants/makemodels'
import { defineComponent } from '~/utils/nuxt3-migration'

export default defineComponent({
  extends: BaseSearchHandler,
  data() {
    return {
      makeValues: [],
      modelValues: [],
      variantValue: null,
      strictTextSearch: false,
      selectedMakesValues: [],
      selectedModelsValues: [],
      selectedRegistration: null
    }
  },
  computed: {
    selectedMakes() {
      return this.allMakesSearchables.filter(make => make.selected)
    },
    selectedModels() {
      if (this.typedModels && this.typedModels.length > 0) {
        // TODO: improve this maybe
        return this.typedModels
          .filter(model => model.selected)
          .concat(this.selectedUntypedModelsSearchables)
      } else {
        return this.selectedUntypedModelsSearchables
      }
    },
    selectedUntypedModelsSearchables() {
      return this.untypedModelsSearchables.filter(model => model.selected)
    },
    showMakes() {
      return this.typedMakes.length !== 0 || this.untypedMakes.length !== 0
    },
    showModels() {
      return this.selectedMakes.length > 0
    },
    hasOptions() {
      return this.typedMakes.length !== 0 || this.untypedMakes.length !== 0
    },
    showRegistration() {
      return this.years.length !== 0
    },
    allMakesSearchables() {
      return this.typedMakes
        .concat(
          this.untypedMakes.map(untypedMake => {
            return { ...untypedMake, v: `untyped:${untypedMake.v}` }
          })
        )
        .map(mapBackendSearchableToSearchable)
    },
    allMakesOptions() {
      return this.topMakes.concat(this.nonTopMakes).map(mapSearchableToOption)
    },
    topMakes() {
      return this.allMakesSearchables.filter(make => make.isTop)
    },
    nonTopMakes() {
      return this.allMakesSearchables.filter(make => !make.isTop)
    },
    typedMakes() {
      return (this.searchables && this.searchables.typed_makemodels) || []
    },
    untypedMakes() {
      return (
        (this.searchables && this.searchables.untyped_makemodels.makes) || []
      )
    },
    untypedModelsSearchables() {
      return this.untypedModels
        .map(untypedModel => {
          return { ...untypedModel, v: `untyped:${untypedModel.v}` }
        })
        .map(mapBackendSearchableToSearchable)
    },
    allModelsOptions() {
      return this.untypedModelsSearchables.map(mapSearchableToOption)
    },
    typedModelsOptionGroups() {
      return this.selectedMakes
        .filter(make => make.children)
        .map(make => {
          return {
            label: make.name,
            options: make.children.map(mapBackendSearchableToOption)
          }
        })
    },
    typedModels() {
      return this.selectedMakes
        .filter(make => make.children)
        .flatMap(make => {
          return make.children.map(mapBackendSearchableToSearchable)
        })
    },
    untypedModels() {
      return (
        (this.handler.searchables &&
          this.handler.searchables.untyped_makemodels.models) ||
        []
      )
    },
    years() {
      // Maybe ad an option to the select to reverse iterate? for performance
      return (this.searchables.years || []).slice().reverse()
    },
    textSearchTooltipText() {
      return this.$i18n.t(
        "some classifieds do not have the make-model filled in the right field. by selecting this option you can also search the classifieds' descriptions"
      )
    },
    icons: () => ({
      infoCircle: faInfoCircle,
      chevronDown: faChevronDown,
      chevronUp: faChevronUp
    })
  },
  methods: {
    onBackendValues({ text_search: strictTextSearch, registration }) {
      this.strictTextSearch = strictTextSearch
      this.selectedMakesValues = this.selectedMakes.map(make => make.value)
      this.selectedModelsValues = this.selectedModels.map(model => model.value)
      this.selectedRegistration = registration
    },
    onClear() {
      this.emitParams([
        ...this.getMakesSearchParams([]),
        ...this.getModelsSearchParams([]),
        {
          name: MAKE_MODELS_REGISTRATION_SEARCH_PARAM,
          value: null
        },
        {
          name: MAKE_MODELS_STRICT_SEARCH_PARAM,
          value: false
        }
      ])
    },
    handleMakesChange(makes) {
      this.emitParams(this.getMakesSearchParams(makes))
    },
    handleModelsChange(models) {
      this.emitParams(this.getModelsSearchParams(models))
    },
    handleStrictTextSearchChange(value) {
      this.emitParams([
        ...this.getMakesSearchParams(this.selectedMakesValues),
        ...this.getModelsSearchParams(this.selectedModelsValues),
        {
          name: MAKE_MODELS_STRICT_SEARCH_PARAM,
          value: !value
        }
      ])
    },
    handleRegistrationChange(value) {
      this.emitParams([
        {
          name: 'registration',
          value
        }
      ])
    },
    getTypedValues(makeModels: any) {
      return makeModels.filter((v: any) => !v.toString().startsWith('untyped:'))
    },
    getModelsSearchParams(models: any[]) {
      return [
        // typed
        {
          name: MODELS_IDS_SEARCH_PARAM,
          value: this.getTypedValues(models)
        },
        // untyped
        {
          name: MODELS_SEARCH_PARAM,
          value: this.getUntypedValues(models)
        }
      ]
    },
    getUntypedValues(makeModels: any) {
      return makeModels
        .filter((v: any) => v.toString().startsWith('untyped:'))
        .map((v: any) => v.replace('untyped:', ''))
    },
    getMakesSearchParams(makes: any[]) {
      return [
        // typed
        {
          name: MAKES_IDS_SEARCH_PARAM,
          value: this.getTypedValues(makes)
        },
        // untyped
        {
          name: MAKES_SEARCH_PARAM,
          value: this.getUntypedValues(makes)
        }
      ]
    }
  }
})
