







import { defineComponent, PropType } from '~/utils/nuxt3-migration'
import { PricePerUnit } from '~/models/classified/types'

export default defineComponent({
  props: {
    pricePerUnit: {
      type: Object as PropType<PricePerUnit>,
      required: false,
      default: null
    },
    wrapped: {
      type: Boolean,
      default: false
    }
  }
})
