var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-relative",attrs:{"id":("ddm-" + _vm.id)}},[_c('CDropdown',{ref:"dropdownTemplateRef",attrs:{"triggers":_vm.triggers,"placement":_vm.position,"distance":0,"no-arrow":"","delay":"0","no-padding":"","popper-class":_vm.popperClass,"max-width":_vm.maxWidth || '100%',"min-width":_vm.minWidth || (_vm.hasCustomTriggerSlot ? '250' : '100'),"container":_vm.hasCustomTriggerSlot ? undefined : ("#ddm-" + _vm.id),"disabled":_vm.disabled,"content-class":"tw-py-1"},on:{"show":function($event){return _vm.$emit('show')},"shown":function($event){return _vm.$emit('shown')},"hidden":_vm.handleHidden},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('ul',{ref:"itemsWrapper",class:['tw-relative', _vm.itemsWrapperClass]},[_vm._t("default"),_vm._v(" "),(_vm.loading)?_c('li',{staticClass:"tw-flex tw-justify-center tw-items-center tw-top-0 tw-left-0 tw-absolute tw-w-full tw-h-full tw-bg-white/40",on:{"click":function($event){$event.preventDefault();$event.stopPropagation();}}},[_c('div',[_c('CSpinner',{attrs:{"size":"sm"}})],1)]):_vm._e()],2)]},proxy:true}],null,true)},[_c('div',{on:{"keydown":_vm.handleKeydown}},[(_vm.$slots.trigger)?_vm._t("trigger"):_c('CButton',{class:[
          {
            'tw-flex-row-reverse tw-flex tw-items-center': _vm.isPositionLeft
          },
          _vm.buttonClass
        ],attrs:{"variant":_vm.variant,"outline":_vm.outline,"size":_vm.buttonSize,"disabled":_vm.disabled,"icon":_vm.buttonIcon}},[(_vm.text)?_c('span',[_vm._v(_vm._s(_vm.text))]):_vm._e(),_vm._v(" "),(!_vm.noCaret)?_c('fa',{class:{
            'tw-ml-2': !_vm.isPositionLeft && _vm.text,
            'tw-mr-2': _vm.isPositionLeft && _vm.text
          },attrs:{"icon":_vm.caretIcon}}):_vm._e()],1)],2)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }