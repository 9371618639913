
































import BaseSearchHandler from '~/components/car/classifieds/search/facets/handlers/BaseSearchHandler.vue'
import CButtonGroup from '~/components/shared/configurable/button/CButtonGroup.vue'
import CTreeSelect from '~/components/shared/configurable/form/select/tree/CTreeSelect.vue'
import { mapActions } from 'vuex'
import { CLASSIFIED_SEARCH_NS } from '~/store/modules/shared/classifieds/search/state'

import { computed, defineComponent, ref, toRefs } from '~/utils/nuxt3-migration'
import { useI18n } from '~/compositions/i18n'
import CRadioButtonGroup from '~/components/shared/configurable/button/CRadioButtonGroup.vue'

export default defineComponent({
  components: { CRadioButtonGroup, CButtonGroup, CTreeSelect },
  extends: BaseSearchHandler,
  setup(props) {
    const { t } = useI18n()
    const selectedValue = ref(null)
    const { handler } = toRefs(props)

    const searchablesArray = computed(() => {
      const searchables = handler.value.searchables.slice()
      searchables.unshift({
        n: t('all'),
        v: null
      })
      return searchables
    })

    const searchablesOptions = computed(() => {
      return searchablesArray.value.map(s => {
        return { label: s.n, value: s.v }
      })
    })
    return {
      selectedValue,
      searchablesArray,
      searchablesOptions
    }
  },
  data() {
    return {
      selectedValues: []
    }
  },
  methods: {
    ...mapActions(CLASSIFIED_SEARCH_NS, {
      loadSeoUrlSearch: 'loadSeoUrlSearch'
    }),
    onClear() {
      this.emitParams([{ name: this.defaultUrlArgName, value: null }])
    },
    onBackendValues({ selected }) {
      this.selectedValue = selected.length > 0 ? selected[0] : null
      if (this.isQuickSearch) {
        this.selectedValues = selected
      }
    },
    handleValueChange(value: string) {
      this.emitParams([{ name: this.defaultUrlArgName, value }])
    },
    handleRedirectUrlClick(redirectUrl: string) {
      this.loadSeoUrlSearch({
        seoUrl: redirectUrl
      })
    },
    treeChanged(newValues: any[]) {
      this.selectedValues = newValues
      this.emitParams([{ name: this.defaultUrlArgName, value: newValues }])
    }
  }
})
