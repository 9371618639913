








import { defineComponent } from '~/utils/nuxt3-migration'

export default defineComponent({
  props: {
    checked: {
      type: Boolean,
      required: true
    }
  },
  setup(_, { emit }) {
    function handleChange(event: Event) {
      emit('change', event)
    }

    return { handleChange }
  }
})
