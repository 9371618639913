














import BaseSearchHandler from '~/components/car/classifieds/search/facets/handlers/BaseSearchHandler.vue'
import CInputAutocomplete from '~/components/shared/configurable/form/input/CInputAutocomplete.vue'
import { AutocompleteSuggestion } from '~/models/autocomplete'
import { defineComponent } from '~/utils/nuxt3-migration'

interface PartNumber {
  name: string
  value: string
}

interface Data {
  q: string
  partNumbers: PartNumber[]
  suggestions: AutocompleteSuggestion[]
}

export default defineComponent({
  components: {
    CInputAutocomplete
  },
  extends: BaseSearchHandler,
  data(): Data {
    return {
      q: '',
      partNumbers: [],
      suggestions: []
    }
  },
  methods: {
    onBackendValues({ selected }) {
      this.q = selected.value
    },
    async handleInputChange(input: string) {
      this.partNumbers = await this.getPartNumbers(input)
      this.suggestions = this.partNumbers.map(this.getSuggestionFromPartNumber)
    },
    handleSuggestionClear() {
      this.partNumbers = []
      this.suggestions = []
    },
    handleInputSubmit(input: string) {
      this.selectPartNumber(input)
    },
    handleSuggestionSelect(id: string) {
      const partNumber = this.partNumbers.find(
        partNumber => partNumber.name === id
      )
      if (partNumber) {
        this.selectPartNumber(partNumber.name)
      }
    },
    selectPartNumber(partNumberName: string) {
      this.q = partNumberName
      this.partNumbers = []
      this.suggestions = []
      this.emitParams([{ name: this.defaultUrlArgName, value: partNumberName }])
    },
    getSuggestionFromPartNumber(
      partNumber: PartNumber
    ): AutocompleteSuggestion {
      return {
        id: partNumber.name,
        name: partNumber.name
      }
    },
    async getPartNumbers(input: string): Promise<PartNumber[]> {
      const { data } = await this.$axios.get(
        '/api/classifieds/part-number/query/',
        {
          params: {
            q: input
          }
        }
      )
      return data.data.suggestions
    }
  }
})
