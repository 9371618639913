













import { defineComponent } from '~/utils/nuxt3-migration'
import BaseSearchHandler from '~/components/car/classifieds/search/facets/handlers/BaseSearchHandler.vue'
import { mapDeps } from '~/plugins/dependency-container/utils'
import GeocodingService from '~/services/location/geocoding/GeocodingService'
import CLocationAutocompleteInput from '~/components/shared/configurable/location/CLocationAutocompleteInput.vue'
import { GeocodingSearchLocation } from '~/models/location/geocoding'
import { createSearchGeolocationString } from '~/utils/geolocation'

export default defineComponent({
  components: { CLocationAutocompleteInput },
  props: {
    inputClass: { type: String, default: null },
    placeholder: { type: String, default: '' }
  },
  extends: BaseSearchHandler,
  data(): {
    geolocations: GeocodingSearchLocation[]
  } {
    return {
      geolocations: []
    }
  },
  computed: {
    ...mapDeps({
      geocodingService: GeocodingService
    }),
    marginTop() {
      return this.isQuickSearch ? null : 'tw-mt-3'
    }
  },
  methods: {
    onBackendValues({ selected }: any) {
      this.geolocations = selected.geolocations
    },
    onClear() {
      this.geolocations = []
      this.emitParams([
        {
          name: 'rich-geolocation',
          value: []
        }
      ])
    },
    onLocationsChange(locations: GeocodingSearchLocation[]) {
      this.geolocations = locations
      this.$emit('location-change')
      this.emitParams([
        {
          name: 'rich-geolocation',
          value: this.geolocations.map(createSearchGeolocationString)
        }
      ])
    }
  }
})
