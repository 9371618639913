





























import COptionWithCount from '~/components/shared/configurable/form/option/COptionWithCount.vue'
import { mapBackendSearchableToOption } from '~/services/search/formatters'
import ExpandableHandlerContent from '~/components/car/classifieds/search/facets/handlers/ExpandableHandlerContent.vue'
import KeyValueSearchHandler from '~/components/car/classifieds/search/facets/handlers/KeyValueSearchHandler.vue'
import { defineComponent } from '~/utils/nuxt3-migration'

export default defineComponent({
  components: {
    COptionWithCount,
    ExpandableHandlerContent
  },
  extends: KeyValueSearchHandler,
  props: {
    blackCheckmarksColorsIds: {
      type: Array,
      required: false,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      selectedValues: []
    }
  },
  computed: {
    colorsExist() {
      return this.searchables.some(
        searchable => searchable.c > 0 || searchable.s
      )
    },
    colorsOptions() {
      return this.searchables.map(mapBackendSearchableToOption)
    }
  },
  methods: {
    onBackendValues({ selected }) {
      this.selectedValues = selected
    },
    handleValueChange(value) {
      this.emitParams([
        {
          name: this.defaultUrlArgName,
          value
        }
      ])
    },
    optionInSelected(option) {
      return this.selectedValues.includes(option.value)
    }
  }
})
