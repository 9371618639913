





import CBadge from '~/components/shared/configurable/badge/CBadge.vue'
import { defineComponent } from '~/utils/nuxt3-migration'

export default defineComponent({
  components: { CBadge }
})
