




























import BaseSearchHandler from '~/components/car/classifieds/search/facets/handlers/BaseSearchHandler.vue'
import TireDimensionSearchHandler from '~/components/car/classifieds/search/facets/handlers/TireDimensionSearchHandler.vue'
import { mapDeps } from '~/plugins/dependency-container/utils'
import SearchService from '~/services/search/SearchService'
import { Facet } from '~/models/search/types'
import { defineComponent } from '~/utils/nuxt3-migration'

export default defineComponent({
  components: {
    TireDimensionSearchHandler
  },
  extends: BaseSearchHandler,
  props: {
    selectedValues: {
      type: [Array, Object],
      default() {
        return null
      }
    }
  },
  computed: {
    tireRatioFacet() {
      return this.getFacetByName('tire_ratio')
    },
    tireDiameterFacet() {
      return this.getFacetByName('tire_diameter')
    },
    tireWidthFacet() {
      return this.getFacetByName('tire_width')
    },
    ...mapDeps({ searchService: SearchService })
  },
  methods: {
    getBindings(handler: Facet) {
      return this.searchService.getFacetComponentBindings(handler)
    },
    onClear() {
      const tireRatioParamName = this.tireRatioFacet.name
      const tireDiameterParamName = this.tireDiameterFacet.name
      const tireWidthParamName = this.tireWidthFacet.name
      this.emitParams([
        {
          name: `${tireRatioParamName}-from`,
          value: null
        },
        {
          name: `${tireRatioParamName}-to`,
          value: null
        },
        {
          name: `${tireDiameterParamName}-from`,
          value: null
        },
        {
          name: `${tireDiameterParamName}-to`,
          value: null
        },
        {
          name: `${tireWidthParamName}-from`,
          value: null
        },
        {
          name: `${tireWidthParamName}-to`,
          value: null
        }
      ])
    }
  }
})
